text {
  font-weight: bold;
}

#main__dashboard {
  max-width: 1200px;
  margin: auto;
}
#main__dashboard h1 {
  margin: 0;
  padding: 0;
}
#main__dashboard #dashboard__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 30px;
}
#main__dashboard #dashboard__header .toggle_zone {
  display: flex;
}
#main__dashboard #dashboard__header .toggle_zone .toggle {
  width: auto;
  margin: 0 15px 7px;
}
#main__dashboard #dashboard__grid .dashboard-block-flex-zone {
  display: flex;
  justify-content: space-between;
}
#main__dashboard #dashboard__grid .dashboard-block-flex-zone .dashboard-block:first-child {
  margin-right: 15px;
}
#main__dashboard #dashboard__grid .dashboard-block-flex-zone .dashboard-block:last-child {
  margin-left: 15px;
}
#main__dashboard #dashboard__grid .dashboard-block {
  background: #F8F8F8;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
#main__dashboard #dashboard__grid .dashboard-block h2 {
  margin-top: 0;
}
#main__dashboard #dashboard__grid .dashboard-block .refill-success {
  padding: 15px;
  text-align: left;
  border-radius: 10px;
  width: 100%;
  font-weight: bold;
  color: #2CEAA8;
  font-size: 1.2em;
}
#main__dashboard #dashboard__grid .dashboard-block .refill-success svg, #main__dashboard #dashboard__grid .dashboard-block .refill-success svg * {
  fill: #2CEAA8;
  margin-right: 15px;
}
#main__dashboard #dashboard__grid .dashboard-block .figures {
  font-weight: bold;
}
#main__dashboard #dashboard__grid .dashboard-block .figures span {
  font-size: 5em;
  color: #00CAC0;
  display: block;
  font-weight: normal;
}
#main__dashboard #dashboard__grid .dashboard-block .dashboard-chart {
  width: 100%;
}
