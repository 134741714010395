/* Global */
/* Toolbar */
/* Resources */
/* Tasks */
/* Global */
/* Toolbar */
/* Resources */
/* Tasks */
/* Global */
/* Toolbar */
/* Resources */
/* Tasks */
/* Global */
/* Toolbar */
/* Resources */
/* Tasks */
#main__gantt #gantt__gantt {
  margin-top: 30px;
}

#validate-start-of-task__form label {
  font-weight: bold;
  margin: 0;
  margin-top: 15px;
}
#validate-start-of-task__form .btn-resource {
  padding-left: 30px;
  position: relative;
  margin-top: 7px;
  margin-right: 7px;
}
#validate-start-of-task__form .btn-resource:before {
  content: attr(data-resource-position-str);
  position: absolute;
  top: 12px;
  left: 8px;
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: white;
  border: 1px solid #999;
  border-radius: 1000px;
  color: #999;
}
#validate-start-of-task__form .btn-resource.selected {
  background: #00CAC0 !important;
}
#validate-start-of-task__form .btn-resource.selected:before {
  color: #00CAC0;
  border-color: #00CAC0;
}
#validate-start-of-task__form .btn-resource.selected:after {
  content: attr(data-order);
  position: absolute;
  right: -7px;
  top: -7px;
  width: 15px;
  height: 15px;
  border-radius: 1000px;
  font-size: 12px;
  background: #00CAC0;
  color: white;
}
#validate-start-of-task__form p.not-enough-quantity {
  color: #00CAC0;
  font-weight: bold;
}

#main__history .history__header button {
  margin-right: 7px;
}
#main__history .task-state {
  font-size: 12px;
  color: white;
  padding: 3.5px 7px;
  border-radius: 10px;
  cursor: default;
  user-select: none;
}
#main__history .task-state.task-state--done {
  background: grey;
}
#main__history .task-state.task-state--incoming {
  background: orange;
}
#main__history .task-state.task-state--working {
  background: #F9224B;
}
#main__history .task-state.task-state--complete {
  background: #2CEAA8;
}
#main__history tr:hover {
  cursor: pointer;
}
#main__history .task-done td {
  background-color: #CCC;
}
#main__history .project__name {
  font-size: 12px;
  color: white;
  padding: 3.5px 7px;
  border-radius: 10px;
  cursor: default;
  user-select: none;
}
#main__history .flex {
  display: flex;
  justify-content: space-between;
}
#main__history .search-bar {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
  width: 40%;
}
#main__history .search-bar-input {
  width: 20%;
}
#main__history .search-bar-addon-icon {
  height: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 20px !important;
}
#main__history .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
#main__history .rs-table-hover .rs-table-row-header:hover .rs-table-cell {
  background-color: unset;
}
#main__history .rs-table-cell-header-icon-sort {
  fill: #00CAC0;
}
#main__history .rs-table-row:hover {
  cursor: pointer;
}
#main__history .rs-table-row {
  border-bottom: 3px solid white !important;
  padding-left: 15px;
  border-radius: 10px;
}
#main__history .rs-table-row[data-depth="0"] > .rs-table-cell-group > .rs-table-cell {
  font-weight: bold;
}
#main__history .rs-table .rs-table-row:not(.rs-table-row-header),
#main__history .rs-table .rs-table-body-row-wrapper .rs-table-body-wheel-area .rs-table-row:not(.rs-table-row-header) .rs-table-cell-group,
#main__history .rs-table .rs-table-row:not(.rs-table-row-header) .rs-table-cell {
  background-color: #f5f5f5;
}
#main__history .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
#main__history .rs-table-hover .rs-table-body-row-wrapper .rs-table-body-wheel-area .rs-table-row:not(.rs-table-row-header):hover .rs-table-cell-group,
#main__history .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover .rs-table-cell {
  background-color: white;
}
#main__history .rs-table-cell-header-icon-sort {
  fill: #00CAC0;
}
#main__history .rs-table-cell[role=columnheader] > .rs-table-cell-content {
  font-size: 14px;
  font-weight: bold;
}

#main__task-details h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
  margin-left: 38px;
}
#main__task-details h2 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
#main__task-details .block {
  border-radius: 4px;
  margin: 15px;
  padding: 15px;
}
#main__task-details .button-build-page {
  margin-top: 20px;
}
#main__task-details .connected-printer-interaction {
  font-weight: bold;
}
#main__task-details .toggle-mode-block {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
#main__task-details .toggle-mode {
  display: flex;
  width: auto;
  margin-top: 0;
  margin-left: 10px;
  margin-right: 10px;
}
#main__task-details .details__header {
  background: #333333;
  padding: 30px;
}
#main__task-details .details__header .header__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#main__task-details .details__header .header__title h1 {
  color: white;
  margin: 0;
  display: flex;
}
#main__task-details .details__header .header__title h1 a {
  margin-left: 15px;
}
#main__task-details .details__header .header__title div {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
}
#main__task-details .details__header .header__title div > * {
  flex-shrink: 0;
  margin-bottom: 7px;
}
#main__task-details .details__header .header__title button, #main__task-details .details__header .header__title span {
  padding: 7px 15px;
  margin-left: 7px;
}
#main__task-details .details__header .header__title span.state {
  color: white;
  border-radius: 4px;
  padding: 7px 15px;
}
#main__task-details .details__header .header__title span.state.task-state-upcoming {
  background: #ec6b22;
}
#main__task-details .details__header .header__title span.state.task-state-progress {
  background: #2CEAA8;
}
#main__task-details .details__header .header__title span.state.task-state-done {
  background: #8D8D8D;
}
#main__task-details .details__header .header-details {
  display: flex;
}
#main__task-details .details__header .header-details * {
  color: white;
}
#main__task-details .details__header .header-details div {
  display: flex;
  margin-right: 30px;
}
#main__task-details .details__header .header-details .task-sheduling {
  cursor: pointer;
}
#main__task-details .details__header .header-details .task-priority {
  cursor: pointer;
}
#main__task-details .details__header .task-progression-infos p {
  margin-bottom: 0;
  color: white;
}
#main__task-details .details__header .task-progression-infos p span {
  color: inherit;
}
#main__task-details .message-zone {
  height: 48px;
  background: #FAE9BD;
  border-radius: 3px;
  display: flex;
  margin: 7px 4px;
}
#main__task-details .message-zone img {
  width: 20px;
  height: 20px;
  margin: auto;
  margin-left: 20px;
  margin-right: 24px;
}
#main__task-details .message-zone h3 {
  margin: auto 0;
}
#main__task-details .task-details__body {
  display: flex;
}
#main__task-details .task-details__body .details-content .current-action {
  justify-content: center;
  display: grid;
  text-align: center;
}
#main__task-details .task-details__body .details-content .action-button-zone {
  margin-top: 16px;
  justify-content: space-around;
  display: flex;
}
#main__task-details .task-details__body .details-content .action-button-zone button {
  border-radius: 4px;
  margin: 15px;
  min-width: 130px;
  font-weight: bold;
}
#main__task-details .task-details__body .details-content .action-button-zone button span {
  vertical-align: middle;
  color: white;
  margin-right: 15px;
}
#main__task-details .task-details__body .details-content .connected-printer-messages > p {
  margin-bottom: 0;
}
#main__task-details .task-details__body .details-content .connected-printer-message-success {
  color: #2CEAA8;
}
#main__task-details .task-details__body .details-content .connected-printer-message-success svg > path {
  fill: #2CEAA8;
}
#main__task-details .task-details__body .details-content .connected-printer-message-warning {
  color: #ec6b22;
}
#main__task-details .task-details__body .details-content .connected-printer-message-warning svg > path {
  fill: #ec6b22;
}
#main__task-details .task-details__body .details-content .connected-printer-message-error {
  color: #F9224B;
}
#main__task-details .task-details__body .details-content .connected-printer-message-error svg > path {
  fill: #F9224B;
}
#main__task-details .task-details__body .task-details__menu {
  height: auto;
  width: 58px;
  border-right: 1px solid #CCCCCC;
}
#main__task-details .task-details__body .task-details__menu img {
  margin: 16px 8px;
  cursor: pointer;
}
#main__task-details .task-details__body .task-details__menu img.active {
  border: 0.1px solid #00CAC0;
  box-sizing: border-box;
  border-radius: 3px;
  background: #EFEFEF;
}
#main__task-details .task-details__body .step-details {
  height: 300px;
  overflow-y: auto;
}
#main__task-details .task-details__body .step-details .sub-menu-icone {
  height: 45px;
  width: 45px;
}
#main__task-details .task-details__body .step-details .MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
#main__task-details .task-details__body .step-details .MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
#main__task-details .task-details__body .step-details .accordion-details li {
  padding: 8px 0 8px 0;
  letter-spacing: 0.15px;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  margin-left: 45px;
}
#main__task-details .task-details__body .step-details .accordion-details li.uncomplete {
  color: #CCCCCC;
}
#main__task-details .task-details__body .step-details .accordion-details li .check-circle-icone {
  width: 14px;
  height: 14px;
  fill: #0DF385;
  right: 16px;
  position: absolute;
  margin-top: 5px;
}
#main__task-details .task-details__body .step-details .accordion-details li .check-circle-icone.uncomplete {
  fill: #CCCCCC;
}
#main__task-details .task-details__body .task-events .task-events__event {
  display: flex;
  margin-left: 30px;
  border-left: 4px solid #CCC;
  position: relative;
  height: 50px;
  padding-left: 30px;
}
#main__task-details .task-details__body .task-events .task-events__event svg {
  margin-right: 7px;
}
#main__task-details .task-details__body .task-events .task-events__event:first-child {
  margin-top: 15px;
}
#main__task-details .task-details__body .task-events .task-events__event:last-child {
  height: 20px;
}
#main__task-details .task-details__body .task-events .task-events__event > div:first-child {
  font-weight: bold;
  font-size: smaller;
  margin-right: 15px;
  margin-top: 2px;
  vertical-align: middle;
}
#main__task-details .task-details__body .task-events .task-events__event.sf-event > div:first-child {
  margin-left: 15px;
  font-size: x-small;
  color: #999;
}
#main__task-details .task-details__body .task-events .task-events__event.sf-event > div:last-child {
  margin-top: 2px;
  font-size: smaller;
  color: #999;
}
#main__task-details .task-details__body .task-events .task-events__event.past:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 100%;
  top: 0;
  left: -4px;
  border-left: 5px solid #00CAC0;
}
#main__task-details .task-details__body .task-events .task-events__event:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 1000px;
  top: 0;
  left: -12px;
  background: #f5f5f5;
  border: 4px solid #CCC;
}
#main__task-details .task-details__body .task-events .task-events__event.past:after {
  border-color: #00CAC0;
}
#main__task-details .task-details__body .task-events .task-events__event.late:after {
  border-color: lightcoral;
}
#main__task-details .task-details__body .task-events .task-events__event.sf-event:after {
  height: 0;
  top: 6px;
  border-color: #CCC;
}
#main__task-details .task-details__body .task-events .task-events__event.late:before {
  border-color: #CCC !important;
}

#main__program-task label {
  margin-left: 0;
}
#main__program-task input, #main__program-task select {
  margin-left: 0;
  margin-bottom: 15px;
  display: block;
}
#main__program-task button {
  display: block;
  margin-top: 15px !important;
}

#date-time-duration i {
  cursor: pointer;
}
#date-time-duration p {
  padding: 4px;
}

#date_time_duration__panel {
  padding: 15px;
}
#date_time_duration__panel label {
  margin-bottom: 0;
}
#date_time_duration__panel input[type=time] {
  background: #f5f5f5;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
}
#date_time_duration__panel:focus {
  outline-width: 0;
}
#date_time_duration__panel h2 {
  margin-bottom: 0;
  text-align: center;
}
#date_time_duration__panel .xdsoft_datetimepicker {
  border: none;
  padding: 0 0 0;
}
#date_time_duration__panel .xdsoft_datetimepicker .xdsoft_datepicker {
  margin-left: 0;
}
#date_time_duration__panel .xdsoft_datetimepicker .xdsoft_calendar th {
  border: 0;
  background: white;
}
#date_time_duration__panel .xdsoft_datetimepicker .xdsoft_calendar th:first-child {
  border-top-left-radius: 10px;
}
#date_time_duration__panel .xdsoft_datetimepicker .xdsoft_calendar th:last-child {
  border-top-right-radius: 10px;
}
#date_time_duration__panel .xdsoft_datetimepicker .xdsoft_calendar tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
#date_time_duration__panel .xdsoft_datetimepicker .xdsoft_calendar tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
#date_time_duration__panel .xdsoft_datetimepicker .xdsoft_calendar td {
  border: 0;
  background: white;
}
#date_time_duration__panel .xdsoft_datetimepicker .xdsoft_current {
  background: #00CAC0 !important;
  box-shadow: none;
  border-radius: 5px;
}
#date_time_duration__panel .xdsoft_datetimepicker .xdsoft_calendar td div:hover {
  background: #f5f5f5;
}
#date_time_duration__panel .xdsoft_datetimepicker .xdsoft_calendar td:hover {
  background: #f5f5f5 !important;
  border-radius: 5px;
}

.main__fast-print .dropzone {
  width: 500px;
  height: 200px;
  line-height: 200px;
}

#task-details__panel {
  width: 700px;
}
#task-details__panel span.initials {
  display: inline-block;
  height: 30px;
  width: 30px;
  text-align: center;
  background: #bdd1f9;
  border-radius: 1000px;
  font-weight: bold;
  color: white;
  line-height: 30px;
}
#task-details__panel #details_header__panel .flex {
  display: flex;
  margin: 0;
  padding: 7px;
}
#task-details__panel #details_header__panel .flex table {
  border-spacing: 5px;
}
#task-details__panel #details_header__panel .flex td {
  width: fit-content;
  margin-bottom: 0;
  margin-top: 0;
  white-space: normal;
  padding: 0;
  margin-right: 7px;
}
#task-details__panel #details_header__panel .flex td .project__name {
  font-size: 12px;
  color: white;
  padding: 3.5px 7px;
  border-radius: 10px;
  cursor: default;
  user-select: none;
}
#task-details__panel #details_header__panel .block {
  padding: 7px;
  margin: 7px;
}

.order-information-name {
  display: flex;
  align-items: center;
  height: 50px;
}
.order-information-name .order-information-name-button {
  background-color: unset;
  padding: 0;
}
.order-information-name .order-information-name-button:hover {
  background-color: unset;
}
.order-information-name .order-information-name-input {
  margin: 0 !important;
  width: 75% !important;
}
.order-information-name .order-information-name-input-save {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px !important;
}
.order-information-name form {
  display: flex;
  align-items: center;
}

.gantt-calendar__text {
  margin: 0;
}
.gantt-calendar .gc-main-container {
  display: flex;
  margin: 2em 0;
  border-radius: 10px;
  background: #f8f8f8;
  padding: 2em;
}

.gc-resources {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.gc-resources__title {
  margin: 0;
  margin-bottom: 1em;
  font-size: 2em;
}
.gc-resources .gc-resources-table {
  border-collapse: collapse;
  border-radius: 0;
  width: 100%;
  table-layout: fixed;
  font-size: 0.8em;
}
.gc-resources .gc-resources-table .gc-resource-row {
  position: relative;
  border: 1px solid lightgray;
  height: 85px;
}
.gc-resources .gc-resources-table .gc-resource-row__cell {
  padding: 1em;
  border: none;
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-farm {
  width: 6em;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-farm button {
  font-size: 1em;
  padding: 2px;
  margin-top: 7px;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-separator {
  width: 1px;
  height: inherit;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-separator__bar {
  border: 1px solid lightgray;
  height: 100%;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-printer__name, .gc-resources .gc-resources-table .gc-resource-row .gc-resource-printer__info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-printer__name {
  margin: 0;
  margin-bottom: 0.5em;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 12px;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-printer__info {
  margin-bottom: 1em;
  font-size: 10px;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-printer .gc-materials {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-printer .gc-materials .gc-resource-material-bar {
  position: relative;
  border-radius: 10px;
  background-color: #ebebeb;
  width: 45%;
  height: 1em;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-printer .gc-materials .gc-resource-material-bar__fill {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  background-color: lightgray;
  /* Or replaced in React */
  /* width: Set in React */
  height: inherit;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-printer .gc-materials .warning {
  border: 1px solid #ec6b22;
  padding: 0 3.5px;
  border-radius: 4px;
  color: #ec6b22;
  background: white;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-printer .gc-materials .warning svg, .gc-resources .gc-resources-table .gc-resource-row .gc-resource-printer .gc-materials .warning svg * {
  fill: #ec6b22;
  margin-right: 3.5px;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-menu {
  padding: 0.5em;
  width: 0;
}
.gc-resources .gc-resources-table .gc-resource-row .gc-resource-menu__icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: scale(0.8);
  cursor: pointer;
  padding: 0.5em 0;
}

.gc-resource-menu-actions {
  display: flex;
  flex-direction: column;
}
.gc-resource-menu-actions__item {
  border-radius: 0;
  padding: 1em;
  font-size: 0.8em;
}

.tooltip {
  border-radius: 10px;
}
.tooltip .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tooltip .flex .tooltip__avatar {
  margin-left: 30px;
}
.tooltip > * {
  font-size: 0.8rem;
}
.tooltip .tooltip-title {
  font-weight: bold;
}

.gc-tasks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 3;
}
.gc-tasks .gc-tasks-toolbar {
  margin: 0;
  margin-bottom: 1em;
  text-align: right;
}
.gc-tasks .gc-tasks-toolbar__button {
  background-color: #e6f0f5;
}
.gc-tasks .gc-tasks-container {
  position: relative;
  font-size: 0.8em;
}
.gc-tasks .gc-tasks-container .gc-tasks-time-bar__text-container {
  margin-bottom: 1em;
  height: 2em !important;
}
.gc-tasks .gc-tasks-container .gc-tasks-time-bar .gc-tasks-time-bar-block {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
  /* width: Set in React */
}
.gc-tasks .gc-tasks-container .gc-tasks-time-bar .gc-tasks-time-bar-block__past {
  position: absolute;
  top: 3em;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 250, 250, 0.4);
  width: 100%;
}
.gc-tasks .gc-tasks-container .gc-tasks-time-bar .gc-tasks-time-bar-block__text {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  border-radius: 10px 0 0 10px;
  background-color: rgba(255, 170, 0, 0.3);
  padding: 0.5em 1em;
  width: max-content;
  height: 2em;
  color: darkorange;
}
.gc-tasks .gc-tasks-container .gc-tasks-time-bar .gc-tasks-time-bar-block__line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-right: 1px solid darkorange;
}
.gc-tasks .gc-tasks-container .gc-tasks-movable-time-bar__text-container {
  margin-bottom: 1em;
  height: 2em !important;
}
.gc-tasks .gc-tasks-container .gc-tasks-movable-time-bar .gc-tasks-movable-time-bar-block {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
  /* width: Set in React */
}
.gc-tasks .gc-tasks-container .gc-tasks-movable-time-bar .gc-tasks-movable-time-bar-block__text {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  border-radius: 10px 0 0 10px;
  background-color: rgba(0, 4, 255, 0.3);
  padding: 0.5em 1em;
  width: max-content;
  height: 2em;
  color: darkblue;
}
.gc-tasks .gc-tasks-container .gc-tasks-movable-time-bar .gc-tasks-movable-time-bar-block__line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-right: 1px solid darkblue;
}
.gc-tasks .gc-tasks-container .gc-tasks-cursor {
  margin-bottom: 1em;
}
.gc-tasks .gc-tasks-container .gc-tasks-cursor__zone {
  background-color: lightgray;
  cursor: pointer;
  height: 1em;
}
.gc-tasks .gc-tasks-container .gc-tasks-grid {
  border-collapse: collapse;
  border-radius: 0;
  width: 100%;
  table-layout: fixed;
}
.gc-tasks .gc-tasks-container .gc-tasks-grid .gc-tasks-grid-header .gc-tasks-grid-header-cell {
  padding: 0.5em 0;
  height: 42.5px;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
  text-align: left;
  font-weight: normal;
}
.gc-tasks .gc-tasks-container .gc-tasks-grid .gc-tasks-grid-header .gc-tasks-grid-header-cell__text {
  transform-origin: top left;
  transform: rotate(-45deg);
}
.gc-tasks .gc-tasks-container .gc-tasks-grid .gc-tasks-grid-header .gc-tasks-grid-header-cell__text--grayed-out {
  color: lightgray;
}
.gc-tasks .gc-tasks-container .gc-tasks-grid .gc-tasks-grid-row {
  height: 85px;
}
.gc-tasks .gc-tasks-container .gc-tasks-grid .gc-tasks-grid-row__cell:hover {
  background: rgba(255, 250, 250, 0.4);
}
.gc-tasks .gc-tasks-container .gc-tasks-grid .gc-tasks-grid-row__cell {
  border: 1px solid lightgray;
  background-color: #e6f0f5;
  cursor: pointer;
  padding: 0;
}
.gc-tasks .gc-tasks-container .gc-tasks-grid .gc-tasks-grid-row__cell--grayed-out {
  background-color: #ebebeb;
}
.gc-tasks .gc-tasks-container .gc-task-draggable--external-printer-task {
  background-color: #282828 !important;
}
.gc-tasks .gc-tasks-container .gc-task-draggable--maintenance {
  background-color: white !important;
}
.gc-tasks .gc-tasks-container .gc-task-draggable--maintenance h3, .gc-tasks .gc-tasks-container .gc-task-draggable--maintenance span, .gc-tasks .gc-tasks-container .gc-task-draggable--maintenance div {
  color: #8D8D8D !important;
}
.gc-tasks .gc-tasks-container .gc-tasks-table {
  position: absolute;
  left: 0;
  bottom: 0;
  border-collapse: collapse;
  border-radius: 0;
  width: 100%;
  table-layout: fixed;
  pointer-events: none;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row {
  height: 85px;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell {
  display: flex;
  position: relative;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  width: inherit;
  height: inherit;
  white-space: nowrap;
  overflow: hidden;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-tasks-cell-drag-limit {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 140, 0, 0.3);
  width: 20px;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-tasks-cell-drag-limit--left {
  left: 0;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-tasks-cell-drag-limit--right {
  right: 0;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable {
  position: absolute;
  /* left: Set in React */
  transition: transform 250ms ease;
  transition: min-width 0.25s;
  min-width: 0%;
  border-radius: 10px;
  background-color: #7a7eff;
  overflow: hidden;
  /* width: Set in React */
}
@keyframes task-progress {
  5%, 15% {
    transform: translateX(3px);
  }
  10%, 20% {
    transform: translateX(-3px);
  }
  25% {
    transform: translateX(0);
  }
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable.gc-task-progress {
  animation: task-progress 3s ease-in-out infinite;
  z-index: 1;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable .gc-task-draggable-trigger {
  cursor: grab;
  pointer-events: auto;
  touch-action: none;
  height: 55px;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable .gc-task-draggable-trigger .gc-task-draggable-content {
  position: relative;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable .gc-task-draggable-trigger .gc-task-draggable-content__name, .gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable .gc-task-draggable-trigger .gc-task-draggable-content__info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable .gc-task-draggable-trigger .gc-task-draggable-content__name {
  font-size: 14px;
  margin: 0px 5px;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable .gc-task-draggable-trigger .gc-task-draggable-content__info {
  font-size: 10px;
  position: relative;
  top: -10px;
  margin-left: 5px;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable .gc-task-draggable-trigger .gc-task-draggable-content__avatar {
  position: absolute;
  top: 0;
  right: 0;
  transform: scale(0.8);
  pointer-events: none;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable .gc-task-draggable-trigger .gc-task-draggable-content__dissociate button {
  position: absolute;
  bottom: 2px;
  right: 2px;
  padding: 2px;
  min-width: 0;
  min-height: 0;
  width: 18px !important;
  height: 18px !important;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable .gc-task-draggable-trigger .gc-task-draggable-content__dissociate button:hover {
  background-color: #282828;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable .gc-task-draggable-trigger .gc-task-draggable-content__dissociate button:hover span {
  color: white;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable .gc-task-draggable-trigger .gc-task-draggable-content__dissociate button span {
  font-size: 16px;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable--selected {
  background-color: #5656bb;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable--dragging {
  z-index: 1;
  transform: scale(1, 1.05);
  background-color: rgba(128, 128, 128, 0.5);
  user-select: none;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable--dragging .gc-task-draggable-trigger {
  cursor: grabbing;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable--drag-disabled .gc-task-draggable-trigger {
  cursor: wait;
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable--external-task {
  background-color: rgba(128, 128, 128, 0.5);
}
.gc-tasks .gc-tasks-container .gc-tasks-table .gc-tasks-row .gc-tasks-cell .gc-task-draggable--external-task .gc-task-draggable-trigger {
  cursor: not-allowed;
}

.gc-toolbar {
  display: flex;
  justify-content: space-between;
}
.gc-toolbar .gc-toolbar-display-mode__button:not(:last-child) {
  margin-right: 0.5em;
}
.gc-toolbar .gc-toolbar-display-mode__button--selected {
  background-color: #e6f0f5;
}
.gc-toolbar .gc-toolbar-date-selection__button:not(:last-child), .gc-toolbar .gc-toolbar-date-selection__text:not(:last-child) {
  margin-right: 0.5em;
}
.gc-toolbar .gc-toolbar-date-selection__button {
  position: relative;
  z-index: 2;
}
.gc-toolbar .gc-toolbar-date-selection__button--primary {
  background-color: #e6f0f5;
}
.gc-toolbar .gc-toolbar-date-selection__text {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.gc-toolbar .gc-toolbar-date-selection__text--animate-from-left, .gc-toolbar .gc-toolbar-date-selection__text--animate-from-right {
  animation: 0.4s ease translation;
}
.gc-toolbar .gc-toolbar-date-selection__text--animate-from-left {
  --translation-origin: -50%;
}
.gc-toolbar .gc-toolbar-date-selection__text--animate-from-right {
  --translation-origin: 50%;
}
@keyframes translation {
  0% {
    transform: translateX(var(--translation-origin, 0));
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.main__resource-choice {
  z-index: 10;
  position: absolute;
  right: 30px;
  bottom: 10px;
  width: 400px;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  color: black;
}
.main__resource-choice .resource-choice-title {
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 4px 4px 0 0;
  font-weight: bold;
  font-size: 16px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
}
.main__resource-choice .resource-choice-close-button {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.main__resource-choice .resource-choice-close-button:hover {
  background-color: unset;
}
.main__resource-choice .resource-choice-content {
  max-height: 500px;
  overflow-y: scroll;
}
.main__resource-choice .resource-choice-task {
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content {
  padding: 10px;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  min-height: 30px;
  font-size: 12px;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-header .resource-choice-task-name {
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-header .resource-choice-task-description-date {
  margin: 0 3px;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-header .resource-choice-task-close-loader {
  padding-bottom: 5px;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-header .resource-choice-task-close-button {
  background-color: unset;
  font-size: 18px;
  padding: 5px;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-header .resource-choice-task-close-button:hover {
  color: black;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-close-button-icon {
  font-size: 18px;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-material-label {
  font-size: 12px;
  font-weight: bold;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-material-info {
  font-size: 12px;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-select, .main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-select > * {
  width: 100%;
  cursor: pointer;
  margin-top: 5px;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-select > *, .main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-select > * > * {
  cursor: pointer !important;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-second-resource {
  margin-top: 10px;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-save-button {
  margin-top: 10px;
  background-color: #00CAC0;
  padding: 6px 10px;
  width: 60px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-save-button > * {
  color: white;
}
.main__resource-choice .resource-choice-task .resource-choice-task-content .resource-choice-task-save-button:hover {
  background-color: #00BDB3;
}

.fc-timeline-events {
  padding-bottom: 0 !important;
}
.fc-timeline-events .fc-timeline-event-harness {
  padding: 10px 0;
}
.fc-timeline-events .fc-timeline-event-harness .fc-timeline-event {
  border-radius: 4px !important;
  padding: 0 7px !important;
  user-select: none;
  height: 47px !important;
  position: relative;
}
.fc-timeline-events .fc-timeline-event-harness .fc-timeline-event * {
  color: white;
}
.fc-timeline-events .fc-timeline-event-harness .fc-timeline-event.booking-slot * {
  color: #666;
}
.fc-timeline-events .fc-timeline-event-harness .fc-timeline-event.booking-slot * .owner-initials {
  background: #f5f5f5;
}
.fc-timeline-events .fc-timeline-event-harness .fc-timeline-event.bouncy-slot {
  animation: bouncy-slot-animation 1.5s infinite ease-in-out;
}
@keyframes bouncy-slot-animation {
  0% {
    transform: scale(1.025);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.025);
  }
}
.fc-timeline-events .fc-timeline-event-harness .fc-timeline-event.multi-selected {
  border: 2px solid white !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px;
}
.fc-timeline-events .fc-timeline-event-harness .fc-timeline-event.not-auto-scheduled {
  border-left: 4px solid #F9224B !important;
}
.fc-timeline-events .fc-timeline-event-harness .fc-timeline-event.start-validated, .fc-timeline-events .fc-timeline-event-harness .fc-timeline-event.start-validated * {
  cursor: not-allowed;
}
.fc-timeline-events .fc-timeline-event-harness .fc-timeline-event .fc-event-title {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.fc-timeline-events .fc-timeline-event-harness .fc-timeline-event .fc-event-main * {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.fc-timeline-events .fc-timeline-event-harness .fc-timeline-event .owner-initials {
  position: absolute;
  top: 0;
  right: 0;
}

.fc-resource-group > div {
  display: flex;
  align-items: center;
}

.fc-datagrid-cell > div {
  background: white;
  display: flex;
  align-items: center;
}

.fc-button-primary {
  background: white !important;
  border: 1px solid #CCC !important;
  color: #999 !important;
  transition: all 0.2s ease-in-out !important;
}
.fc-button-primary:hover {
  background: #f7f7f7;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 4px;
}
.fc-button-primary:focus {
  box-shadow: unset !important;
}

.fc-prev-button {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.fc-next-button {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.fc-icon:before {
  color: #999;
}

.fc-button-group .fc-button {
  padding: 7px 15px !important;
}
.fc-button-group .fc-button:first-child {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.fc-button-group .fc-button:last-child {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.fc-resource .fc-icon, .fc-resource .fc-datagrid-expander {
  display: none;
}
.fc-resource .fc-datagrid-cell-cushion {
  padding: 0 !important;
  width: 100% !important;
}
.fc-resource .printer-header {
  height: 60px;
  padding: 0 4px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 0.2s ease-in-out;
  width: 100%;
}
.fc-resource .printer-header .header-flex {
  display: flex;
}
.fc-resource .printer-header .header-flex-space {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fc-resource .printer-header .data-cell {
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  border-right: 1px solid #CCC;
  padding-right: 15px;
  margin-right: 15px;
}
.fc-resource .printer-header .details {
  display: block;
  font-size: 12px;
  color: #999;
}
.fc-resource .printer-header .schedule-zone button {
  padding: 7px 15px;
}
.fc-resource .printer-header .schedule-zone button svg {
  margin: 0;
}
.fc-resource .printer-header .data-door-open {
  height: 100%;
  width: 60px;
  min-width: 60px;
  margin-right: 0;
  text-align: center;
  display: flex;
  align-items: center;
  white-space: break-spaces;
  font-weight: bold;
  color: white;
  background: #00CAC0;
  border-radius: 4px;
}

.fc-resource-timeline th {
  border-bottom: none !important;
}
.fc-resource-timeline .fc-resource-group {
  display: none;
}

.fc-lane-off-days {
  background: rgba(204, 204, 204, 0.3);
}

#gantt-header {
  background: white;
  border: 1px solid #ddd;
  margin-bottom: -1px;
  padding: 7px;
  text-align: center;
}

.xdsoft_datetimepicker, .xdsoft_datetimepicker * {
  transition: unset;
}
.xdsoft_datetimepicker button:hover, .xdsoft_datetimepicker * button:hover {
  background: unset !important;
}
