@charset "UTF-8";
@font-face {
  font-family: "Cabin";
  src: url("/src/fonts/Cabin/cabin-regular-webfont.woff2") format("woff2"), url("/src/fonts/Cabin/cabin-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/src/fonts/Google-Material-Icons.woff2") format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

* {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: inherit;
  box-sizing: border-box;
  color: #666;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: white;
}

body {
  font-size: 0.85em;
}
body.app-body {
  overflow: hidden;
}

p {
  margin: 15px 0;
}

.hidden {
  display: none;
}

.no-margin {
  margin: 0;
}

.no-outline {
  outline: none;
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.font-no-space {
  font-size: 0;
}

.half-opacity {
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}

/* Basic elements */
.flex-div {
  display: flex;
}
.flex-div > * {
  width: 100%;
}

.half-width {
  width: 50%;
}

/* Form elements */
textarea, input[type=text], input[type=password], select, input[type=number] {
  background: white;
  padding: 7px 10.5px;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  box-shadow: #f0f0f0 0 1px 1px;
  outline: none;
  color: #444;
  margin: 7px;
  width: 300px;
}
textarea:focus, input[type=text]:focus, input[type=password]:focus, select:focus, input[type=number]:focus {
  border: 2px solid #00CAC0;
  padding: 6px 9.5px;
}

label {
  display: block;
  text-align: left;
  width: 300px;
  margin: 0 auto;
  font-size: 0.8em;
}

input[type=text][disabled], input[type=password][disabled], select[disabled], input[type=number][disabled] {
  background: transparent;
  color: rgba(0, 0, 0, 0.38);
}

input[type=text].html-duration-picker {
  background: #e8e8e8;
  padding: 10px 30px 10px 20px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
}

.html-duration-picker-wrapper button {
  margin-top: 7px !important;
  border-radius: 0;
  background: transparent;
}

textarea {
  width: 100%;
  min-height: 150px;
  margin-left: 0;
  margin-right: 0;
}

input[type=submit], .button-link, button {
  background: #F8F8F8;
  border: none;
  padding: 11px 22px;
  border-radius: 4px;
  text-decoration: none;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}
input[type=submit] > i, input[type=submit] > svg, .button-link > i, .button-link > svg, button > i, button > svg {
  margin-right: 7px;
  transition: color 0.2s ease-in-out;
}
input[type=submit] > strong, .button-link > strong, button > strong {
  transition: color 0.2s ease-in-out;
}
input[type=submit].button-icon, .button-link.button-icon, button.button-icon {
  min-height: 28px;
}
input[type=submit].button-icon i, .button-link.button-icon i, button.button-icon i {
  margin-right: 0;
}
input[type=submit]:not([disabled]):hover, .button-link:not([disabled]):hover, button:not([disabled]):hover {
  background: #00CAC0;
  color: white;
}
input[type=submit]:not([disabled]):hover strong, .button-link:not([disabled]):hover strong, button:not([disabled]):hover strong {
  color: white;
}
input[type=submit][disabled], .button-link[disabled], button[disabled] {
  cursor: not-allowed;
  background: #CCC !important;
  color: #999 !important;
}
input[type=submit][disabled] i, .button-link[disabled] i, button[disabled] i {
  color: #999 !important;
}
input[type=submit].btn-white, .button-link.btn-white, button.btn-white {
  background: white;
}
input[type=submit].btn-main-color, .button-link.btn-main-color, button.btn-main-color {
  border-color: transparent;
  background: #00CAC0;
  color: white;
}
input[type=submit].btn-main-color:hover, .button-link.btn-main-color:hover, button.btn-main-color:hover {
  color: white;
  border-color: transparent;
  background: #00e4d8;
}
input[type=submit].btn-main-color *, .button-link.btn-main-color *, button.btn-main-color * {
  color: white;
}
input[type=submit].btn-big, .button-link.btn-big, button.btn-big {
  padding: 8px 30px;
}
input[type=submit].btn-full-width, .button-link.btn-full-width, button.btn-full-width {
  width: 100%;
}
input[type=submit].selected, .button-link.selected, button.selected {
  background: #00CAC0;
  color: white;
  border-color: #00CAC0;
}
input[type=submit].selected:hover, .button-link.selected:hover, button.selected:hover {
  background: #00CAC0;
}
input[type=submit].button-success, input[type=submit].button-success i, .button-link.button-success, .button-link.button-success i, button.button-success, button.button-success i {
  background: #2CEAA8 !important;
  border-color: #2CEAA8;
  color: white;
  font-weight: bold;
}
input[type=submit].button-warning, input[type=submit].button-warning i, .button-link.button-warning, .button-link.button-warning i, button.button-warning, button.button-warning i {
  background: #ec6b22 !important;
  border-color: #ec6b22;
  color: white;
  font-weight: bold;
}
input[type=submit].button-error, input[type=submit].button-error i, .button-link.button-error, .button-link.button-error i, button.button-error, button.button-error i {
  background: #F9224B !important;
  border-color: #F9224B;
  color: white;
  font-weight: bold;
}

.btn-group {
  display: inline-block;
  font-size: 0;
}
.btn-group input[type=submit], .btn-group .button-link, .btn-group button {
  margin-left: 0 !important;
  margin-right: 0 !important;
  display: inline-block;
  border-left: none;
  border-radius: 0;
}
.btn-group input[type=submit]:first-child, .btn-group .button-link:first-child, .btn-group button:first-child {
  border-left: 1px solid #CCC;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.btn-group input[type=submit]:last-child, .btn-group .button-link:last-child, .btn-group button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox] + label {
  margin-bottom: -3px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
}

input[type=checkbox] + label > span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 1000px;
  background: lightgray;
  vertical-align: middle;
  margin: -2px 4px 0 0;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

input[type=checkbox] + label > span:after {
  content: "✓";
  display: inline-block;
  color: white;
  font-weight: bold;
  transform: scale(0);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

input[type=checkbox]:checked + label > span:after {
  transform: scale(1);
  opacity: 1;
}

input[type=checkbox]:checked + label > span {
  background: #00CAC0;
}

/* Lightboxes */
#lightbox-back {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  background: rgba(0, 0, 0, 0.8);
}
#lightbox-back .lightbox {
  display: none;
  position: absolute;
  top: 10%;
  left: 50%;
  margin-left: -250px;
  background: white;
  min-width: 600px;
  max-height: 80%;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.5) 0 5px 5px;
}
#lightbox-back .lightbox h2 {
  background: #f5f5f5;
  margin: 0;
  padding: 15px 30px;
  text-align: center;
}
#lightbox-back .lightbox .lightbox__content {
  padding: 15px 30px;
  text-align: center;
}

/* Avatar */
.avatar {
  display: inline-block;
  background: #bdd1f9;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: white !important;
  font-weight: bold;
  border-radius: 1000px;
  user-select: none;
}
.avatar.avatar-with-menu {
  cursor: pointer;
}
.avatar.avatar-huge {
  width: 200px;
  height: 200px;
  line-height: 200px;
  font-size: 3em;
  margin: 30px auto;
}
.avatar.avatar-normal-small {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-weight: normal;
  font-size: 0.9em;
}
.avatar.avatar-small {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-weight: normal;
  font-size: 0.8em;
}
.avatar.avatar-mini {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-weight: normal;
  font-size: 0.8em;
}
.avatar + .avatar {
  margin-left: 7px;
}

/* Loading */
.loading-container {
  width: 80%;
  margin: 30px auto;
  padding: 15px 0;
  text-align: center;
}
.loading-container.inline {
  margin: 0;
  width: 100px;
}

/* Drop zones */
.dropzone {
  width: 70%;
  height: 250px;
  line-height: 250px;
  text-align: center;
  margin: 60px auto;
  background: white;
  border-radius: 10px;
  border: 2px dashed #CCC;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.2s ease-in-out;
}

.dropzone.dropzone-small {
  height: 150px;
  line-height: 150px;
}

.dropzone:hover {
  color: #f9224b;
  border-color: #f9224b;
}

.dropzone.dragging {
  color: #f9224b;
  border-color: #f9224b;
  transform: scale(1.03);
  background-position: center center;
}

.dropzone p {
  margin: 0;
}

.progresszone {
  text-align: center;
}

.progresszone p {
  font-size: 18px;
  margin-bottom: 30px;
}

.progresszone p span {
  font-size: 1em;
}

/* Tables */
[class^=fc] > table td.fc-timeline-slot {
  padding: 1px;
}

:not([class^=fc]) > table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0 7px;
  table-layout: fixed;
}
:not([class^=fc]) > table tr:not(.container):hover td {
  background: #f3f3f3;
}
:not([class^=fc]) > table.small-spacing {
  border-spacing: 0 3.5px;
}
:not([class^=fc]) > table th, :not([class^=fc]) > table td {
  padding: 11.2px 30px;
  text-align: left;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}
:not([class^=fc]) > table th:first-child, :not([class^=fc]) > table td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
:not([class^=fc]) > table th:last-child, :not([class^=fc]) > table td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
:not([class^=fc]) > table th.container, :not([class^=fc]) > table td.container {
  padding: 0;
  background: transparent;
}
:not([class^=fc]) > table th.container table, :not([class^=fc]) > table td.container table {
  border-spacing: 0;
}
:not([class^=fc]) > table th button, :not([class^=fc]) > table th .button-link, :not([class^=fc]) > table th input[type=submit], :not([class^=fc]) > table td button, :not([class^=fc]) > table td .button-link, :not([class^=fc]) > table td input[type=submit] {
  margin: 0;
  font-size: 14px;
  padding: 3.5px 15px;
  background: white;
  min-width: 50px;
}
:not([class^=fc]) > table th button > i, :not([class^=fc]) > table th button > svg, :not([class^=fc]) > table th .button-link > i, :not([class^=fc]) > table th .button-link > svg, :not([class^=fc]) > table th input[type=submit] > i, :not([class^=fc]) > table th input[type=submit] > svg, :not([class^=fc]) > table td button > i, :not([class^=fc]) > table td button > svg, :not([class^=fc]) > table td .button-link > i, :not([class^=fc]) > table td .button-link > svg, :not([class^=fc]) > table td input[type=submit] > i, :not([class^=fc]) > table td input[type=submit] > svg {
  margin-right: 0;
}
:not([class^=fc]) > table th {
  color: #999;
  padding-bottom: 7px;
}
:not([class^=fc]) > table td {
  background: #F8F8F8;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
:not([class^=fc]) > table td:first-child {
  border-left: 1px solid #f5f5f5;
}
:not([class^=fc]) > table td:last-child {
  border-right: 1px solid #f5f5f5;
}
:not([class^=fc]) > table.table--compact th, :not([class^=fc]) > table.table--compact td {
  padding: 7px 15px;
}

#root {
  height: 100%;
  max-height: 100%;
}

/* Grid container */
#main-grid {
  display: grid;
  grid-template-columns: 90px auto auto;
  grid-template-rows: 70px auto;
  /*grid-template-areas: "command-palette command-palette command-palette header"
  					 "main-menu sub-menu main-content main-content";*/
  height: 100%;
  max-height: 100vh;
  width: 100%;
  max-width: 100vw;
  background: #F8F8F8;
}
#main-grid #main-grid__item--tabs {
  grid-area: 1/1/span 1/span 2;
  padding-left: 90px;
  background: url("../../images/logos/handddle_256.png") #F8F8F8;
  background-size: 42px;
  background-position: 24px center;
  background-repeat: no-repeat;
}
#main-grid #main-grid__item--tabs #tabs {
  height: 100%;
  position: relative;
}
#main-grid #main-grid__item--tabs #tabs .tabs__tab {
  display: inline-block;
  width: 120px;
  height: 100%;
  padding-top: 35px;
  text-align: center;
  position: relative;
  margin-left: 30px;
  text-decoration: none;
}
#main-grid #main-grid__item--tabs #tabs .tabs__tab.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 30px;
  right: 30px;
  height: 4px;
  background: #333;
}
#main-grid #main-grid__item--header {
  grid-area: 1/3/span 1/span 1;
}
#main-grid #main-grid__item--header #header {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#main-grid #main-grid__item--main-menu {
  grid-area: 2/1/span 1/span 1;
  background: #222;
  max-height: calc(100vh - 70px);
}
#main-grid #main-grid__item--main-content {
  grid-area: 2/2/span 1/span 2;
  background: white;
  max-height: 100%;
  overflow: auto;
}
#main-grid.view-content-only #main-grid__item--main-content {
  grid-area: 1/1/span 2/span 3;
}

/* Command palette */
#command-palette {
  border-radius: 100px;
  border-bottom-left-radius: 0;
  height: 100px;
  margin-top: -50px;
  background: #00CAC0;
}

#command-palette__input {
  background: #f5f5f5;
  padding: 10px 20px;
  border-radius: 1000px;
  border: none;
  margin-top: 83px;
  margin-left: 20px;
  width: 80%;
  font-size: 14px;
}

/* Main menu */
#main-menu {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #333333;
}
#main-menu .main-menu__menu-item {
  display: block;
  text-decoration: none;
  width: 44px;
  height: 44px;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center center;
  color: #999;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
  margin: 15px auto 0;
  user-select: none;
  transition: all 0.2s ease-in-out;
  position: relative;
}
#main-menu .main-menu__menu-item:hover, #main-menu .main-menu__menu-item.main-menu__menu-item--active {
  background-color: #555;
  border-color: #00CAC0;
  color: #ddd;
}
#main-menu .main-menu__menu-item#main-menu__menu-item--pic {
  background-color: #00CAC0;
  background-image: url("../../images/icons/main_menu/pic_builder.svg");
  background-size: 16px 16px;
}
#main-menu .main-menu__menu-item#main-menu__menu-item--dashboard {
  background-image: url("../../images/icons/main_menu/dashboard.svg");
}
#main-menu .main-menu__menu-item#main-menu__menu-item--gantt {
  background-image: url("../../images/icons/main_menu/gantt.svg");
}
#main-menu .main-menu__menu-item#main-menu__menu-item--manager {
  background-image: url("../../images/icons/main_menu/pic_manager.svg");
}
#main-menu .main-menu__menu-item#main-menu__menu-item--resources {
  background-image: url("../../images/icons/main_menu/resources.svg");
}
#main-menu .main-menu__menu-item#main-menu__menu-item--projects {
  background-image: url("../../images/icons/main_menu/projects.svg");
}
#main-menu .main-menu__menu-item#main-menu__menu-item--library {
  background-image: url("../../images/icons/main_menu/library.svg");
}
#main-menu .main-menu__menu-item#main-menu__menu-item--farm {
  background-image: url("../../images/icons/main_menu/farm.svg");
}
#main-menu .main-menu__menu-item#main-menu__menu-item--settings {
  background-image: url("../../images/icons/main_menu/settings.svg");
}
#main-menu p {
  font-size: 12px;
  margin: 7px auto;
  text-align: center;
}

/* Sub menu */
/* @todo SCSS format */
#sub-menu {
  background: #333;
  height: 100%;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
}

#sub-menu h2 {
  text-align: center;
  color: #ddd;
  padding-bottom: 30px;
  position: relative;
  margin-bottom: 30px;
}

#sub-menu h2:after {
  content: " ";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: #00CAC0;
  border-radius: 1000px;
}

#sub-menu h3 {
  font-size: 14px;
  background: #ddd;
  text-align: center;
  padding: 7px 15px;
  border-radius: 10px;
  margin: 0;
}

#sub-menu p {
  color: #ddd;
}

.sub-menu__space {
  height: 15px;
}

/* Main content */
#main-content {
  padding: 30px;
  border-top: 1px solid #f5f5f5;
  min-width: 800px;
}
#main-content, #main-content > p {
  color: #444;
}
#main-content .block {
  margin-left: 0;
  margin-right: 0;
}

.block {
  background: #F8F8F8;
  margin: 30px 30px 0;
  border-radius: 4px;
  padding: 30px;
}
.block h2 {
  margin-top: 0;
}
.block *:last-child {
  margin-bottom: 0;
}
.block table {
  table-layout: fixed;
}
.block table td > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.block table td:first-child {
  font-weight: bold;
  width: 30%;
}
.block [class^=fc] > table td:first-child {
  font-weight: normal !important;
  width: auto !important;
}
.block input[type=submit], .block button {
  background: white;
}
.block.block-error {
  background: #FF3838;
}
.block.block-error * {
  color: white;
}
.block.block-error button {
  color: #FF3838;
}
.block.block-error button:hover {
  background: white;
}

#header {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 15px;
  background: #F8F8F8;
}
#header #header__world {
  width: 50px;
  height: 50px;
  background-image: url("../../images/icons/main_menu/world.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 7px;
  border-radius: 10px;
  transition: background 0.2s ease-in-out;
}
#header #header__world:hover {
  background-color: white;
}
#header #header__search-zone {
  padding-right: 15px;
  border-right: 1px solid #f5f5f5;
}
#header #header__notifications {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  transition: background 0.2s ease-in-out;
}
#header #header__notifications:hover {
  background: white;
}
#header #header__notifications i {
  font-size: 24px;
  font-weight: normal;
}
#header #header__notifications .__react_component_tooltip.show {
  opacity: 1 !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 10px;
  max-height: 500px;
  overflow-y: auto;
}
#header #header__notifications .__react_component_tooltip.place-bottom::after {
  left: 354px !important;
}

/*****************/
/* Notifications */
/*****************/
#notifications__panel {
  overflow-y: auto;
  margin-top: 70px;
  background: #f5f5f5;
  height: 100%;
}
#notifications__panel h2 {
  font-size: 24px;
}
#notifications__panel p {
  padding: 15px 30px;
}
#notifications__panel #notifications__list .notification__header {
  display: flex;
  padding: 0 15px;
  margin-bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 4px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 10;
}
#notifications__panel #notifications__list .notification__header .header__read-all {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  background: #f5f5f5;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#notifications__panel #notifications__list .notification__header .header__read-all:hover {
  background: #00CAC0;
}
#notifications__panel #notifications__list .button__read-all {
  position: absolute;
  right: 10px;
  top: 5px;
  outline: none;
}
#notifications__panel #notifications__list .list__item-notification {
  padding: 15px 30px;
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid white;
  background: #f5f5f5;
  cursor: pointer;
}
#notifications__panel #notifications__list .list__item-notification h3 {
  font-size: 1.1em;
  margin: 0;
}
#notifications__panel #notifications__list .list__item-notification p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
#notifications__panel #notifications__list .list__item-notification.notification--unread {
  background: white;
}
#notifications__panel #notifications__list .list__item-notification.notification--unread:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background: #00CAC0;
}
#notifications__panel #notifications__list .list__item-notification .notification__read {
  position: absolute;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  background: #f5f5f5;
  top: 50%;
  margin-top: -12px;
  right: 30px;
  display: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#notifications__panel #notifications__list .list__item-notification .notification__read:hover {
  background: #00CAC0;
}
#notifications__panel #notifications__list .list__item-notification.notification--unread:hover .notification__read {
  display: block;
}
#notifications__panel #notifications__list .list__item-notification .notification__date {
  display: block;
  font-size: 12px;
  color: #999;
  margin: 7px auto 0;
}
#notifications__panel #notifications__list .list__item-notification.color0, #notifications__panel #notifications__list .list__item-notification.color0:before {
  border-color: #f9224b;
}
#notifications__panel #notifications__list .list__item-notification.color1, #notifications__panel #notifications__list .list__item-notification.color1:before {
  border-color: #31af91;
}
#notifications__panel #notifications__list .list__item-notification.color2, #notifications__panel #notifications__list .list__item-notification.color2:before {
  border-color: #f6cb74;
}
#notifications__panel #notifications__list .list__item-notification.color3, #notifications__panel #notifications__list .list__item-notification.color3:before {
  border-color: #00aed3;
}

/*****************/
/* User Actions  */
/*****************/
#user__panel {
  display: grid;
  padding: 15px 0;
}
#user__panel a {
  text-decoration: none;
  padding: 7px 15px;
  border-radius: 10px;
  transition: background 0.2s ease-in-out;
}
#user__panel a:hover {
  background: #f5f5f5;
}
#user__panel a i {
  margin-right: 7px;
}
#user__panel a i .fa-users {
  font-size: 80%;
}

.modal__overlay {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
}
.modal__overlay .modal__dialog {
  background-color: white;
  border-radius: 4px;
  margin: 90px auto;
  max-width: 100%;
  width: 500px;
  overflow: hidden;
}
.modal__overlay .modal__dialog .dialog__content {
  padding: 30px;
}
.modal__overlay .modal__dialog .dialog__content .content__title {
  font-size: 18px;
  margin-top: 0;
}
.modal__overlay .modal__dialog .dialog__content p {
  margin-bottom: 0;
}
.modal__overlay .modal__dialog .dialog__footer {
  background: #F8F8F8;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}
.modal__overlay .modal__dialog .dialog__footer button {
  margin-left: 15px;
}
.modal__overlay.corner {
  background: transparent;
  backdrop-filter: none;
  pointer-events: none;
}
.modal__overlay.corner .modal__dialog {
  position: absolute;
  left: 105px;
  bottom: 15px;
  margin: 0;
  width: auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0 7px 15px;
  background-color: #333333;
}
.modal__overlay.corner .modal__dialog .dialog__content {
  padding: 15px 30px;
}
.modal__overlay.corner .modal__dialog .dialog__content .content__title {
  color: #ddd;
  font-weight: normal;
  pointer-events: auto;
  font-size: 16px;
  margin-top: 0;
}

#digit-code-container {
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  width: 0;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
#digit-code-container.visible {
  width: 100%;
  opacity: 1;
  z-index: 2500;
}
#digit-code-container #digit-code-form {
  background: white;
  border-radius: 4px;
  width: 70%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px;
}
#digit-code-container #digit-code-form p {
  text-align: center;
  font-size: 18px;
  margin: 30px;
}
#digit-code-container #digit-code-form > div {
  display: flex;
  justify-content: center;
}
#digit-code-container #digit-code-form > div .digit__number {
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  background: #CCC;
  border-radius: 1000px;
  margin: 7px;
  font-size: 24px;
  user-select: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#digit-code-container #digit-code-form > div .digit__number:hover {
  transform: scale(1.1);
  background: #00CAC0;
  color: white;
}
#digit-code-container #digit-code-form .digit__progress {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: auto 7px;
  border-radius: 1000px;
  border: 2px solid #999;
  transition: background 0.2s ease-in-out;
}
#digit-code-container #digit-code-form .digit__progress.filled {
  background: #999;
}

.main__lists_toolbars {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main__lists_toolbars .filter-list {
  margin-right: 10px;
  width: 25%;
}
.main__lists_toolbars .filter-list > div {
  width: 100%;
}
.main__lists_toolbars .toolbar-left {
  display: flex;
  width: 100%;
}
.main__lists_toolbars .toolbar-right {
  min-width: 25%;
  display: flex;
  justify-content: end;
}
.main__lists_toolbars input {
  margin: 0 5px;
  background: none;
}
.main__lists_toolbars input:hover {
  background: none;
}
.main__lists_toolbars .search-bar {
  width: 30%;
  max-width: 350px;
}

.simple-button div {
  height: 20px;
  display: flex;
  align-items: center;
}

.simple-button-bold {
  font-weight: bold;
}

.simple-button-fill span {
  color: white;
}

.simple-button-main-fill {
  background-color: #00CAC0;
}
.simple-button-main-fill:hover {
  background-color: #00BDB3 !important;
}

.simple-button-warn-fill {
  background-color: #ec6b22;
}
.simple-button-warn-fill:hover {
  background-color: #d95615 !important;
}

.simple-button-error-fill {
  background-color: #F9224B;
}
.simple-button-error-fill:hover {
  background-color: #be1e3c !important;
}

.simple-button-default-fill {
  background-color: #CCC;
}
.simple-button-default-fill:hover {
  background-color: #999 !important;
}

.simple-button-outlined {
  background-color: unset;
  border: 2px solid;
}
.simple-button-outlined span {
  transition: all 0.2s ease-in-out;
}
.simple-button-outlined:hover {
  background-color: unset !important;
}
.simple-button-outlined:hover span {
  transition: all 0.2s ease-in-out;
}

.simple-button-main-outlined {
  border-color: #00CAC0;
}
.simple-button-main-outlined:hover {
  border-color: #00BDB3;
}
.simple-button-main-outlined:hover span {
  color: #00BDB3 !important;
}
.simple-button-main-outlined span {
  color: #00CAC0;
}

.simple-button-warn-outlined {
  border-color: #ec6b22;
}
.simple-button-warn-outlined:hover {
  border-color: #d95615;
}
.simple-button-warn-outlined:hover span {
  color: #d95615 !important;
}
.simple-button-warn-outlined span {
  color: #ec6b22;
}

.simple-button-error-outlined {
  border-color: #F9224B;
}
.simple-button-error-outlined:hover {
  border-color: #be1e3c;
}
.simple-button-error-outlined:hover span {
  color: #be1e3c !important;
}
.simple-button-error-outlined span {
  color: #F9224B;
}

.simple-button-default-outlined {
  border-color: #CCC;
}
.simple-button-default-outlined:hover {
  border-color: #999;
}
.simple-button-default-outlined:hover span {
  color: #999 !important;
}
.simple-button-default-outlined span {
  color: #CCC;
}

.simple-button-icon {
  font-size: 24px;
  margin-right: 5px;
}
