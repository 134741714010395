.table-heatmap .months-labels {
  width: 610px;
  margin-bottom: 5px;
  padding-left: 30px;
  display: flex;
  justify-content: space-around;
  font-size: 0.7em;
  font-weight: bold;
}
.table-heatmap .table-heatmap-grid {
  height: 100px;
  position: relative;
}
.table-heatmap .table-heatmap-grid .block-day {
  background: #2CEAA8;
  border-radius: 2px;
  width: 9px;
  height: 9px;
  display: inline-block;
  position: absolute;
}
.table-heatmap .table-heatmap-grid .block-day:not(.future):hover {
  transform: scale(2);
  border: 1px solid gray;
  z-index: 10;
}
.table-heatmap .table-heatmap-grid .block-day.warn {
  background: #f09264;
}
.table-heatmap .table-heatmap-grid .block-day.crit {
  background: #F9224B;
}
.table-heatmap .table-heatmap-grid .block-day.future {
  background: #f5f5f5;
  border: 1px solid #e8e8e8;
}
.table-heatmap .table-heatmap-grid .day-label {
  font-size: 0.7em;
  font-weight: bold;
  height: 11px;
  vertical-align: middle;
  width: 30px;
  text-align: right;
  padding-right: 8px;
  transform: translateY(-3px);
}

@keyframes led-bounce {
  0% {
    width: 8px;
    height: 8px;
    top: 4px;
    left: 4px;
    opacity: 1;
  }
  30%, 100% {
    width: 16px;
    height: 16px;
    top: 0px;
    left: 0px;
  }
  50%, 100% {
    opacity: 0;
  }
}
#main__farm .farm__zone {
  position: relative;
}
#main__farm .farm__zone #farm__ehs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f5f5f5;
  padding: 15px;
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  height: 100%;
  text-align: center;
}
#main__farm .farm__zone #farm__ehs img {
  width: 250px;
  margin: 15px auto;
}
#main__farm .farm__zone #farm__ehs h3.error {
  font-size: 1.6em;
  line-height: 1.5;
  padding: 15px;
  border-radius: 4px;
  background: #FFB2B2;
  color: #F9224B;
}
#main__farm #farm__container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 30px;
  align-items: flex-start;
}
#main__farm #farm__container #farm__tryit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1.1);
}
#main__farm #farm__container #farm__list {
  min-width: 150px;
  height: 100%;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#main__farm #farm__container #farm__list .list__element {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
#main__farm #farm__container #farm__list #list__add {
  border: 1px solid #CCC;
}
#main__farm #farm__container #farm__modules {
  width: 80%;
  margin: auto;
  position: relative;
}
#main__farm #farm__container #farm__modules #farm__error {
  z-index: 10;
  background: rgba(245, 245, 245, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #CCC;
  border-radius: 10px;
  padding: 10%;
  display: none;
}
#main__farm #farm__container #farm__modules #farm__error p {
  text-align: center;
  font-size: 24px;
}
#main__farm #farm__container #farm__modules .modules__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
}
#main__farm #farm__container #farm__modules .aspect-ratio-41 {
  padding-bottom: 41%;
}
#main__farm #farm__container #farm__modules .modules__module {
  width: 100%;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border: 2px solid #CCC;
  padding: 7px;
  padding-bottom: 0;
  border-radius: 10px;
  max-width: 300px;
}
#main__farm #farm__container #farm__modules .modules__module:after {
  content: "Porte ouverte";
  position: absolute;
  background: white;
  left: 7px;
  right: 7px;
  bottom: -20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 5px;
  animation: door-open 2s infinite;
  opacity: 0;
  transition: bottom 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
#main__farm #farm__container #farm__modules .modules__module.door-open:after {
  bottom: 7px;
  opacity: 1;
}
@keyframes door-open {
  0% {
    transform: scale(1.03);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.03);
  }
}
#main__farm #farm__container #farm__modules .modules__module .module__cell {
  height: 100%;
  margin-bottom: 7px;
  border-radius: 10px;
  position: relative;
  background-color: #CCC;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 60px 60px;
  transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
  display: flex;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell[data-color="8"] {
  background-color: #ffffff !important;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell[data-color="2"] {
  background-color: #f04040 !important;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell[data-color="3"] {
  background-color: #40f040 !important;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell[data-color="5"] {
  background-color: #00cac0 !important;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell[data-color="4"] {
  background-color: #ffa500 !important;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 10;
  transition: opacity 0.2s ease-in-out;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell[data-state=s0] {
  background-color: gainsboro;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell[data-state=s1] {
  background-color: #c2c2c2;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell[data-state=waiting] {
  background-color: #ededed;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell[data-state=waiting]:before {
  height: 100%;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell[data-state=waiting] .cell__controls {
  opacity: 0.5;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell[data-state=s-disabled] {
  background-color: #ededed;
  border-color: transparent;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell[data-state=s-disabled] .cell__controls {
  opacity: 0.5;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell.manual:after {
  content: "Manuel";
  position: absolute;
  top: -7px;
  right: -7px;
  padding: 0 7px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: white;
  background: #00CAC0;
  border-radius: 1000px;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell .cell__controls {
  padding: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.2s ease-in-out;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell .cell__controls .controls__control {
  width: 100%;
  max-width: 50px;
  height: 50px;
  border-radius: 10px;
  transition: background 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell .cell__controls .controls__control:hover, #main__farm #farm__container #farm__modules .modules__module .module__cell .cell__controls .controls__control.active {
  background: white;
}
#main__farm #farm__container #farm__modules .modules__module .module__cell p {
  background: white;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  width: 70%;
  margin: auto;
}
#main__farm .farm-panel-data {
  width: 200px;
}
#main__farm .farm-panel-data .panel__title {
  display: flex;
  justify-content: space-between;
}
#main__farm .farm-panel-data .colors-selection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}
#main__farm .farm-panel-data .colors-selection span {
  display: block;
  height: 20px;
  width: 30px;
  border-radius: 4px;
  border: 1px solid #666;
  cursor: pointer;
}
#main__farm .farm-panel-data .colors-selection span.selected {
  border: 2px solid #00CAC0;
}
#main__farm .farm-panel-data .colors-selection span[data-color="0"] {
  background: #f5f5f5;
}
#main__farm .farm-panel-data .colors-selection span[data-color="1"] {
  background: white;
}
#main__farm .farm-panel-data .colors-selection span[data-color="2"] {
  background: rgba(44, 234, 168, 0.5);
}
#main__farm .farm-panel-data .colors-selection span[data-color="3"] {
  background: rgba(249, 34, 75, 0.5);
}
#main__farm .farm-panel-data .colors-selection span[data-color="4"] {
  background: rgba(176, 213, 239, 0.5);
}
#main__farm .farm-panel-data button {
  display: block;
  margin: auto;
  margin-bottom: 7px;
}
#main__farm .farm-panel-data input[type=number], #main__farm .farm-panel-data input[type=text] {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
#main__farm .farm-panel-data .farm-data-setter-slider {
  height: 15px;
  border-radius: 1000px;
  margin: 15px 0;
}
#main__farm .farm-panel-data .farm-data-setter-slider .ui-slider-range-min {
  border-radius: 1000px;
  background: #00CAC0;
}
#main__farm .farm-panel-data .farm-data-setter-slider .ui-slider-handle {
  width: 21px;
  height: 21px;
  border-radius: 1000px;
  background: white;
}

.farm__dialog {
  background: #f5f5f5;
}
.farm__dialog .font-bold {
  font-weight: bold;
}
.farm__dialog h2 {
  font-size: 16px;
  margin-bottom: 6px;
}
.farm__dialog .farm-dialog-title-bloc {
  background-color: white;
  padding: 10px 10px 10px 30px;
}
.farm__dialog .farm-dialog-title {
  font-size: 20px;
  font-weight: bold;
  width: 100%;
}
.farm__dialog .farm-dialog-title .environment-name {
  font-size: 0.6em;
  float: right;
  margin-top: 5px;
  margin-right: 20px;
}
.farm__dialog .connection-smart-farm-error {
  margin: 30px;
  padding: 10px 20px;
  background-color: #ffe2e2;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.farm__dialog .connection-smart-farm-error span {
  color: #F9224B;
}
.farm__dialog .connection-smart-farm-error .smart-farm-error-text {
  font-weight: bold;
}
.farm__dialog .connection-smart-farm-error .smart-farm-error-icon {
  margin-right: 10px;
}
.farm__dialog .button-outlined-main {
  background-color: unset;
  color: #00CAC0;
  font-weight: bold;
  padding: 6px 8px;
}
.farm__dialog .button-outlined-main:not([disabled]) {
  border: 2px solid #00CAC0;
}
.farm__dialog .flex {
  display: flex;
}
.farm__dialog .block {
  flex-shrink: 0;
  display: inline-block;
  border-radius: 10px;
  margin: 0 15px 15px 0;
}
.farm__dialog .block button:first-child {
  margin-right: 7px;
}
.farm__dialog .block h3 {
  font-size: 1.4em;
  font-weight: normal;
  text-align: right;
  color: #00CAC0;
}
.farm__dialog .block.block-graph {
  width: 45%;
}
.farm__dialog .farm__dialog_system_status_block {
  margin-top: 30px;
}

.printer-state-icon {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 1000px;
  margin-right: 7px;
}
.printer-state-icon.printer-state-icon--active {
  background: lightgreen;
}
.printer-state-icon.printer-state-icon--error {
  background: red;
}
.printer-state-icon.printer-state-icon--alert {
  background: orange;
}

#main__printers table td:first-child {
  font-weight: bold;
}
#main__printers table .printer-state {
  font-size: 12px;
  color: white;
  padding: 3.5px 7px;
  border-radius: 10px;
  cursor: default;
  user-select: none;
}
#main__printers table .printer-state.printer-state--free {
  background: #2CEAA8;
}
#main__printers table .printer-state.printer-state--upcoming {
  background: orange;
}
#main__printers table .printer-state.printer-state--working {
  background: #F9224B;
}

#main__printer-details .resource-change {
  overflow-y: scroll;
  height: 250px;
}
#main__printer-details table {
  margin-bottom: 30px;
}
#main__printer-details label {
  width: auto;
}
#main__printer-details input[type=text], #main__printer-details select {
  margin-left: 0;
}
#main__printer-details #details__head {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin: 30px 0;
}
#main__printer-details #details__head > div {
  width: 40%;
}
#main__printer-details #details__head #head__image #image__image {
  height: 100px;
  background: white;
  border-radius: 10px;
}
#main__printer-details #details__notes {
  background: white;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
}
#main__printer-details #details__notes p {
  margin: 0;
}
#main__printer-details #details__notes textarea {
  background: white;
  resize: none;
  padding: 15px;
}
#main__printer-details #details__files-list .files-list__file-item {
  display: inline-block;
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 10px;
  margin-right: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
  background: white;
}
#main__printer-details #details__files-list .files-list__file-item:hover {
  transform: scale(1.03);
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px;
}
#main__printer-details #dropzone__printer-files {
  display: none;
}

.printer-controllable-printer-error {
  color: #F9224B;
}
.printer-controllable-printer-error svg > path {
  fill: #F9224B;
}

#main__add-printer label {
  margin-left: 0;
}
#main__add-printer input, #main__add-printer select {
  margin-left: 0;
  margin-bottom: 15px;
  display: block;
}
#main__add-printer #is_fiber {
  margin-bottom: 30px;
}

option:disabled {
  color: #CCCCCC;
}

#main__edit-printer label {
  margin-left: 0;
}
#main__edit-printer input, #main__edit-printer select {
  margin-left: 0;
  margin-bottom: 15px;
  display: block;
}
#main__edit-printer #dual-extruder-zone {
  margin-bottom: 30px;
}
#main__edit-printer option:disabled {
  color: #CCCCCC;
}

#main__resources .resources__material-title span {
  margin-top: 30px;
  font-size: 16px;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 10px 15px;
  font-weight: bold;
  display: block;
  cursor: pointer;
}
#main__resources .resources__table-header {
  cursor: pointer;
}
#main__resources table {
  table-layout: fixed;
}
#main__resources table tr.nearly-empty td {
  background-color: rgba(249, 34, 75, 0.2);
  color: #F9224B;
  font-weight: bold;
}
#main__resources table tr.nearly-empty td .button-link {
  font-weight: normal;
}
#main__resources table tr.nearly-empty td.resource__stock {
  position: relative;
}
#main__resources table tr.nearly-empty td.resource__stock:before {
  content: "\f071";
  font-family: FontAwesome;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 14px;
  left: 0;
  font-size: 18px;
  font-weight: normal;
}
#main__resources table tr.nearly-empty-main td.resource__stock {
  position: relative;
}
#main__resources table tr.nearly-empty-main td.resource__stock:before {
  content: "\f071";
  font-family: FontAwesome;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 14px;
  left: 0;
  font-size: 18px;
  font-weight: normal;
  color: #ec6b22;
}
#main__resources table tr.resources__table-header td:first-child {
  font-weight: bold;
}
#main__resources table td.container {
  padding-left: 50px;
  border: none;
}
#main__resources table .progress {
  background: #f5f5f5;
  height: 8px;
  width: 100px;
  border-radius: 1000px;
  overflow: hidden;
  position: relative;
}
#main__resources table .progress .progress__complete {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: green;
}
#main__resources table .resource__stock > * {
  display: inline-block;
}
#main__resources table .resource__stock p {
  margin: 0;
  margin-left: 7px;
  font-size: 12px;
  color: inherit;
}

#main__resource-details #details__files-list .files-list__file-item {
  display: inline-block;
  width: 300px;
  text-decoration: none;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-right: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}
#main__resource-details #details__files-list .files-list__file-item:hover {
  transform: scale(1.03);
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px;
}

#main__add-resource h2 {
  font-size: 16px;
}
#main__add-resource h3 {
  font-size: 14px;
}
#main__add-resource .last-block {
  margin-bottom: 20px;
}
#main__add-resource .form-message-warning {
  margin-top: 7px;
  color: #F9224B;
  font-size: 12px;
}
#main__add-resource .weight-measure-enabled {
  padding-left: 10px;
  font-weight: bold;
}
#main__add-resource .weight-measure-disabled {
  padding-left: 10px;
  color: #8D8D8D;
  font-weight: normal;
}
#main__add-resource .radio-group {
  display: flex;
  justify-content: space-between;
}
#main__add-resource #add-resource__form .form-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
#main__add-resource #add-resource__form .form-group > div {
  width: 50%;
}
#main__add-resource #add-resource__form .form-group > div:first-child {
  margin-right: 15px;
}
#main__add-resource #add-resource__form .form-group > div:last-child {
  margin-left: 15px;
}
#main__add-resource #add-resource__form .form-group select, #main__add-resource #add-resource__form .form-group input {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}
#main__add-resource #add-resource__form .form-group label {
  margin-top: 7px;
  width: 100%;
}
#main__add-resource #add-resource__form .form-group button {
  margin-top: 15px;
}
#main__add-resource #add-resource__form .form-group #chk-incomplete-resource-zone {
  margin-top: 15px;
  margin-bottom: 15px;
}
#main__add-resource #add-resource__form .form-group .new-brand {
  display: flex;
}
#main__add-resource #add-resource__form .form-group .weight-text-info {
  margin-bottom: 0;
  margin-top: 7px;
  font-size: 12px;
}
#main__add-resource #add-resource__form .form-group .weigh-text-warning {
  color: #ec6b22;
}

#main__edit-resource #edit-resource__form {
  display: flex;
  justify-content: space-between;
}
#main__edit-resource #edit-resource__form > div {
  width: 50%;
}
#main__edit-resource #edit-resource__form > div:first-child {
  margin-right: 15px;
}
#main__edit-resource #edit-resource__form > div:last-child {
  margin-left: 15px;
}
#main__edit-resource #edit-resource__form select, #main__edit-resource #edit-resource__form input {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}
#main__edit-resource #edit-resource__form label {
  margin-top: 7px;
  width: 100%;
}
#main__edit-resource #edit-resource__form button {
  margin-top: 15px;
}
#main__edit-resource #edit-resource__form #chk-incomplete-resource-zone {
  margin-top: 15px;
}
#main__edit-resource #edit-resource__form #remaining-quantity-zone {
  display: none;
}

.main__resources_list_table {
  margin-left: 30px;
}
.main__resources_list_table input[type=text] {
  margin: 0;
  width: 50px;
  padding: 2px 4px;
  text-align: right;
  background: transparent;
}
.main__resources_list_table input[type=text]:hover, .main__resources_list_table input[type=text]:focus {
  padding: 2px 4px;
  background: white;
}
.main__resources_list_table .rs-table-row:hover[data-depth="1"] {
  cursor: pointer;
}
.main__resources_list_table .rs-table-row {
  border-bottom: 3px solid white !important;
}
.main__resources_list_table .rs-table-row[data-depth="0"] > .rs-table-cell-group > .rs-table-cell {
  font-weight: bold;
}
.main__resources_list_table .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover[data-depth="1"],
.main__resources_list_table .rs-table-hover .rs-table-row:hover[data-depth="1"] .rs-table-cell-group,
.main__resources_list_table .rs-table-hover .rs-table-row:hover[data-depth="1"] .rs-table-cell {
  background-color: white;
}
.main__resources_list_table .rs-table .rs-table-row:not(.rs-table-row-header),
.main__resources_list_table .rs-table .rs-table-row:not(.rs-table-row-header) .rs-table-cell-group,
.main__resources_list_table .rs-table .rs-table-row:not(.rs-table-row-header) .rs-table-cell {
  background-color: #f5f5f5;
}
.main__resources_list_table .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
.main__resources_list_table .rs-table-hover .rs-table-row-header:hover .rs-table-cell {
  background-color: unset;
}
.main__resources_list_table .rs-table-cell-header-icon-sort {
  fill: #00CAC0;
}
.main__resources_list_table .rs-table-cell[role=columnheader] > .rs-table-cell-content {
  font-size: 14px;
  font-weight: bold;
}
.main__resources_list_table .quantity-infos {
  display: flex;
}
.main__resources_list_table .quantity-infos-text {
  margin-left: 10px;
}
.main__resources_list_table .action-buttons {
  display: flex !important;
}

.button-table-link {
  margin-top: -11px;
  margin-right: 5px;
  background-color: white;
  height: 38px;
  display: flex;
  align-items: end;
}
.button-table-link > svg {
  margin: 0;
}

.main__resources_stock_level_donut .donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 20px;
  height: 20px;
}
.main__resources_stock_level_donut .donut-chart .slice {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.main__resources_stock_level_donut .donut-chart .slice.one {
  clip: rect(0 20px 10px 0);
}
.main__resources_stock_level_donut .donut-chart .slice.two {
  clip: rect(0 10px 20px 0);
}
.main__resources_stock_level_donut .donut-chart .chart-center {
  position: absolute;
  border-radius: 50%;
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  background: #f5f5f5;
}
.main__resources_stock_level_donut .donut-chart .chart-center span {
  display: block;
  text-align: center;
  line-height: 8px;
}

.main__resources_list .resources-filter-list {
  margin-right: 10px;
  width: 25%;
}
.main__resources_list .resources-filter-list > div {
  width: 100%;
}
.main__resources_list .resources-toolbar {
  display: flex;
  justify-content: space-between;
}
.main__resources_list .resources-toolbar-left {
  display: flex;
  width: 100%;
}
.main__resources_list .resources-toolbar-right {
  min-width: 25%;
  display: flex;
  justify-content: end;
}

#main__resource_management .action-button-list {
  margin-top: 15px;
}
#main__resource_management .action-button {
  margin-right: 10px;
}

#main__resource_history th {
  padding: 2px;
}
#main__resource_history td {
  padding: 2px;
}
#main__resource_history .history-table {
  overflow-y: scroll;
  height: 250px;
  margin-top: 10px;
}

#main__resource_datablock .datablock-display {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
}
#main__resource_datablock p {
  margin: 0;
}
#main__resource_datablock .datablock {
  background-color: #f5f5f5;
  border-radius: 13px;
  padding: 10px 20px;
  min-width: 17%;
  margin-right: 30px;
}
#main__resource_datablock .datablock-one-line-content {
  margin-top: 15px;
}
#main__resource_datablock .datablock-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
#main__resource_datablock .datablock-number {
  font-size: 18px;
  font-weight: bold;
}
#main__resource_datablock .datablock-big-number {
  font-size: 24px;
  font-weight: bold;
}
#main__resource_datablock .datablock-resource-name {
  margin-top: 10px;
}
#main__resource_datablock .datablock-resource-name .datablock-resource-material {
  font-weight: bold;
}
#main__resource_datablock .datablock-batchnumber {
  font-size: 16px;
  font-weight: bold;
}
#main__resource_datablock .datablock-date {
  font-size: 12px;
}
#main__resource_datablock .datablock-small-text {
  font-size: 12px;
}

.farm__dialog_environment_manager .header-block {
  display: flex;
  justify-content: space-between;
}
.farm__dialog_environment_manager .grid-zone {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.farm__dialog_environment_manager .grid-zone .right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.farm__dialog_environment_manager .grid-zone .right button {
  padding: 7px 15px;
  margin-right: 15px;
}
.farm__dialog_environment_manager .lighting-choices {
  display: flex;
  justify-content: space-between;
}
.farm__dialog_environment_manager .light-color-zone {
  padding: 7px 15px;
  border-radius: 10px;
}
.farm__dialog_environment_manager .light-color-label {
  font-weight: bold;
  margin-right: 6px;
}
.farm__dialog_environment_manager .door-block {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.farm__dialog_environment_manager .door-status {
  margin-left: 20px;
}
.farm__dialog_environment_manager .door-status-label {
  margin-right: 5px;
}

.rs-anim-fade.rs-anim-in {
  z-index: 1500 !important;
}

.rs-btn-ghost {
  border: 1px solid #00CAC0;
}

.rs-btn-ghost:active, .rs-btn-active, .rs-btn-ghost:hover, .rs-btn-ghost:focus {
  border: 1px solid #00CAC0 !important;
  background-color: #00CAC0 !important;
  color: white !important;
}

.farm__dialog_graphics_data_range {
  display: flex;
  justify-content: space-between;
  padding: 7px 0;
}
.farm__dialog_graphics_data_range .data-range-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.farm__dialog_graphics_data_range .data-range-export-button {
  display: flex;
  align-items: center;
  padding: 4px 6px;
}
.farm__dialog_graphics_data_range .data-range-export-button > * {
  color: #00CAC0;
}
.farm__dialog_graphics_data_range .data-range-export-button:hover > * {
  color: white;
}
.farm__dialog_graphics_data_range .data-range-export-button-icon {
  margin-right: 5px;
}
.farm__dialog_graphics_data_range .data-range-select-block {
  display: flex;
  align-items: center;
}
.farm__dialog_graphics_data_range .data-range-custom-select-block {
  margin-left: 10px;
}
.farm__dialog_graphics_data_range .data-range-custom-select-label {
  font-weight: bold;
  margin-right: 5px;
}

.environment-pollution-info {
  position: relative;
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: end;
  z-index: 20;
}
.environment-pollution-info:hover {
  cursor: pointer;
}

#environment-pollution-info-tooltip-tooltip > .MuiTooltip-tooltip {
  color: black !important;
  background-color: white !important;
  border: 1px solid #f5f5f5;
}
#environment-pollution-info-tooltip-tooltip > .MuiTooltip-tooltip .environment-pollution-info-tooltip-title {
  font-weight: bold;
  font-size: 12px;
}
#environment-pollution-info-tooltip-tooltip > .MuiTooltip-tooltip .environment-pollution-info-tooltip-description {
  margin-top: 5px;
}
#environment-pollution-info-tooltip-tooltip > .MuiTooltip-tooltip .environment-pollution-info-tooltip-description-title {
  font-weight: bold;
}

.farm__dialog_environment_blocks .farm__dialog_environment_blocks-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.farm__dialog_environment_blocks .environment-left-block {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 200px;
}
.farm__dialog_environment_blocks .environment-pollution-ok-nok {
  background: #f3f3f3;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 1000px;
  text-align: center;
  border: 4px solid black;
  font-weight: bold;
  box-sizing: content-box;
}
.farm__dialog_environment_blocks .environment-pollution-ok-nok.ok {
  border-color: #2CEAA8;
  color: #2CEAA8;
}
.farm__dialog_environment_blocks .environment-pollution-ok-nok.nok {
  border-color: #F9224B;
  color: #F9224B;
}
.farm__dialog_environment_blocks .environment-pollution-left-block {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.farm__dialog_environment_blocks .environment-pollution-left-block .environment-pollution-left-block-left-donuts {
  display: flex;
  justify-content: space-around;
  align-items: start;
}
.farm__dialog_environment_blocks .environment-pollution-left-block .environment-pollution-type-select {
  margin-top: 10px;
  width: 80%;
}
.farm__dialog_environment_blocks .environment-pollution-left-block .environment-pollution-type-select .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .farm__dialog_environment_blocks .environment-pollution-left-block .environment-pollution-type-select .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black;
}
.farm__dialog_environment_blocks .environment-pollution-left-block .environment-pollution-change-filter-block {
  background: #F8F8F8;
  border-radius: 4px;
  text-align: center;
}
.farm__dialog_environment_blocks .environment-pollution-left-block .environment-left-block-line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.farm__dialog_environment_blocks .environment-left-block-line {
  width: 100%;
  display: flex;
  justify-content: center;
}
.farm__dialog_environment_blocks .environment-pollution-right-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 60%;
}
.farm__dialog_environment_blocks .environment-slider {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}
.farm__dialog_environment_blocks .environment-slider-content {
  width: 60%;
}
.farm__dialog_environment_blocks .environment-slider-legend {
  width: 150px;
}
.farm__dialog_environment_blocks .environment-pollution-slider-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  margin: 15px 0;
}
.farm__dialog_environment_blocks .environment-pollution-slider {
  width: 100%;
}
.farm__dialog_environment_blocks .environment-pollution-slider-content {
  width: 100%;
}
.farm__dialog_environment_blocks .environment-graph-block {
  width: 60%;
  background: gray;
  display: contents;
}
.farm__dialog_environment_blocks .environment-graph-block > div, .farm__dialog_environment_blocks .environment-graph-block > div > div, .farm__dialog_environment_blocks .environment-graph-block > div > div > svg {
  width: 100% !important;
}
.farm__dialog_environment_blocks .environment-graph {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.farm__dialog_environment_blocks .environment-graph-legend {
  padding-left: 30px;
  display: flex;
  align-items: center;
  margin-top: -8px;
}
.farm__dialog_environment_blocks .environment-graph-legend-icon {
  margin-right: 5px;
  font-size: 20px;
}
.farm__dialog_environment_blocks .need-filter-label {
  display: flex;
  justify-content: center;
  font-weight: 500;
}
.farm__dialog_environment_blocks .need-filter-value {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.farm__dialog_environment_blocks .need-filter-change-date {
  font-size: 12px;
  color: #666;
}
.farm__dialog_environment_blocks .donut-chart-value {
  font-weight: bold;
  font-size: 26px;
}
.farm__dialog_environment_blocks .donut-chart-value-line {
  height: 20px;
  position: relative;
  top: -10px;
}
.farm__dialog_environment_blocks .donut-chart-value-line-unit {
  font-size: 14px;
  display: flex;
  justify-content: center;
}
.farm__dialog_environment_blocks .donut-chart-value-small {
  font-weight: bold;
  font-size: 16px;
}
.farm__dialog_environment_blocks .donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.farm__dialog_environment_blocks .donut-chart .slice {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.farm__dialog_environment_blocks .donut-chart .slice.one {
  clip: rect(0 100px 50px 0);
}
.farm__dialog_environment_blocks .donut-chart .slice.two {
  clip: rect(0 50px 100px 0);
}
.farm__dialog_environment_blocks .donut-chart .chart-center {
  position: absolute;
  border-radius: 50%;
  top: 8px;
  left: 8px;
  width: 84px;
  height: 84px;
  background: #f5f5f5;
}
.farm__dialog_environment_blocks .donut-chart .chart-center span {
  display: block;
  text-align: center;
  line-height: 84px;
}

.farm__dialog_status_blocks .status-label {
  margin-right: 5px;
}
.farm__dialog_status_blocks .status-value {
  font-weight: bold;
}
.farm__dialog_status_blocks .material-stock-button {
  margin-top: 15px;
  margin-bottom: 10px;
}
.farm__dialog_status_blocks .printers_status_printer_name {
  font-weight: bold;
  margin-bottom: 2px;
}
.farm__dialog_status_blocks .printers_status_printer_block {
  margin-bottom: 8px;
}
.farm__dialog_status_blocks .status-toggle {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.farm__dialog_status_blocks .status-toggle label {
  display: contents;
}
.farm__dialog_status_blocks .status-toggle .toggle-label {
  margin-left: 5px;
}

.farm__dialog_history_events .history-events-table {
  overflow-y: scroll;
  height: 250px;
}
.farm__dialog_history_events .toggle {
  display: flex;
  align-items: center;
}
.farm__dialog_history_events .toggle label {
  width: 60px;
  margin: 0;
}

.farm__header {
  display: flex;
  justify-content: space-between;
}
.farm__header .farm-header-solid-block {
  background-color: #f5f5f5;
  padding: 10px 20px;
  border-radius: 4px;
}
.farm__header .farm-header-outlined-block {
  padding: 10px 20px;
  border: 1px solid #E7E7E7;
  border-radius: 4px;
}
.farm__header .farm-header-organization-block .organization-block-content {
  min-width: 200px;
}
.farm__header .farm-header-organization-block .organization-block-label {
  font-size: 12px;
}
.farm__header .farm-header-organization-block .organization-block-value {
  font-size: 16px;
  font-weight: bold;
}
.farm__header .farm-header-organization-block .organization-block-select {
  margin-top: 10px;
}
.farm__header .farm-header-organization-block .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .farm__header .farm-header-organization-block .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black;
}
.farm__header .realtime-hour-block {
  min-width: 160px;
}
.farm__header .connection-block {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
  font-size: 12px;
  min-width: 160px;
}
.farm__header .header-data-label-block {
  margin-right: 20px;
}
.farm__header .header-data-block {
  display: flex;
}
.farm__header .header-data-value {
  font-weight: bold;
}
.farm__header .weather-block {
  display: flex;
  align-items: center;
  min-width: 280px;
}
.farm__header .weather-block .weather-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.farm__header .weather-block .weather-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.farm__header .weather-block .weather-condition {
  display: flex;
  align-items: center;
}
.farm__header .weather-block .weather-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
.farm__header .weather-block .weather-description {
  font-size: 12px;
}
.farm__header .weather-block .weather-icon-path {
  transform: scale(0.8) translate(0, 3px);
}
.farm__header .weather-block .weather-temperature {
  font-size: 28px;
  font-weight: bold;
}
.farm__header .weather-block .weather-info-label {
  font-size: 12px;
  margin-right: 5px;
  text-align: end;
}
.farm__header .weather-block .weather-info-value {
  font-size: 12px;
  font-weight: bold;
  text-align: end;
  min-width: 45px;
}
.farm__header .weather-block .weather-block-right-part {
  margin-left: 15px;
}
.farm__header .weather-block .weather-details {
  display: flex;
}
.farm__header .weather-block .weather-location {
  font-weight: bold;
  text-align: end;
  margin-bottom: 10px;
}
.farm__header .micro-factory-block {
  flex: 1;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.farm__header .micro-factory-block realtime-date {
  font-size: 12px;
}
.farm__header .micro-factory-block .realtime-hour {
  font-weight: bold;
  font-size: 20px;
}
.farm__header .micro-factory-block .external-environment-block .external-environment-title {
  font-size: 16px;
  text-align: center;
  margin-bottom: 5px;
}
.farm__header .micro-factory-block .external-environment-block .external-environment-subtitle {
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  margin-bottom: 3px;
}
.farm__header .micro-factory-block .external-environment-block .external-environment-values {
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.farm__header .micro-factory-block .external-environment-block .environment-data {
  display: flex;
  align-items: center;
  margin: 0 5px;
}
.farm__header .micro-factory-block .external-environment-block .environment-data span {
  color: black;
}
.farm__header .micro-factory-block .external-environment-block .environment-data-value {
  margin-left: 2px;
}

.farm-profile-modal .profile-modal-title {
  font-size: 18px;
  margin-bottom: 15px;
}
.farm-profile-modal label {
  margin-left: 10px;
}
.farm-profile-modal .profile-selection {
  display: inline-flex;
}
.farm-profile-modal .profile-data-inputs {
  display: inline-flex;
}
.farm-profile-modal .profile-data-loading {
  padding: 10px;
}
.farm-profile-modal .farm-profile-form-section-info {
  margin-top: 10px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 12px;
}
.farm-profile-modal .farm-profile-form-buttons {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}
.farm-profile-modal .farm-profile-form-button {
  margin: 0 6px;
}
.farm-profile-modal .button-outlined {
  background-color: unset;
  border: 2px solid #00CAC0;
  color: #00CAC0;
}
.farm-profile-modal .button-main, .farm-profile-modal .button-main > *, .farm-profile-modal .button-main > * > * {
  color: white;
}
.farm-profile-modal .button-main {
  background-color: #00CAC0;
}
.farm-profile-modal .button-main:hover {
  background-color: #00BDB3;
}
.farm-profile-modal .simple-profile-button {
  background-color: unset;
  padding: 12px 0 0 0;
}
.farm-profile-modal .simple-profile-button:hover {
  background-color: unset;
}
.farm-profile-modal .simple-main-button, .farm-profile-modal .simple-main-button > *, .farm-profile-modal .simple-main-button > * > * {
  color: #00CAC0;
}
.farm-profile-modal .simple-main-button:hover, .farm-profile-modal .simple-main-button > *:hover, .farm-profile-modal .simple-main-button > * > *:hover {
  color: #00BDB3;
}
.farm-profile-modal .simple-error-button, .farm-profile-modal .simple-error-button > *, .farm-profile-modal .simple-error-button > * > * {
  color: #F9224B;
}
.farm-profile-modal .simple-error-button:hover, .farm-profile-modal .simple-error-button > *:hover, .farm-profile-modal .simple-error-button > * > *:hover {
  color: #FF3838;
}

.farm__dialog_environment_profiles .header-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 15px;
}
.farm__dialog_environment_profiles .button-outlined-main {
  margin-right: 15px;
}
.farm__dialog_environment_profiles .profile-button {
  margin-right: 5px;
  margin-bottom: 5px;
}
.farm__dialog_environment_profiles .profile-button-running {
  background-color: #00BDB3;
  color: white;
}

.farm-manager-profile-tooltip-text > div {
  color: white;
  font-size: 14px;
  padding: 2px;
}

.farm__structure {
  height: 680px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-bottom: 10px;
}
.farm__structure .farm-element-first {
  margin-left: auto;
}
.farm__structure .farm-element-last {
  margin-right: auto !important;
}

.farm-module {
  width: 300px;
  min-width: 300px;
  height: 100%;
}
.farm-module .module-element-block-center {
  display: flex;
  justify-content: center;
}
.farm-module .farm-module-header {
  background-color: #D8D8D8;
  height: 7%;
  border-radius: 2px 2px 0 0;
}
.farm-module .farm-module-header .module-header-door {
  width: 30%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333;
  color: #C7C7C7;
  font-size: 12px;
  border-radius: 0 0 3px 3px;
  padding: 2px;
}
.farm-module .farm-module-header .module-header-door-open {
  color: #ec6b22;
}
.farm-module .farm-module-header .air-extraction-content {
  display: flex;
  justify-content: center;
}
.farm-module .farm-module-header .air-extraction-text {
  font-size: 10px;
}
.farm-module .farm-module-body {
  background-color: #262626;
  height: 88%;
  border-radius: 0 0 2px 2px;
  padding: 3%;
}
.farm-module .farm-module-footer {
  height: 5%;
  display: flex;
  justify-content: space-between;
  margin: 0 5%;
}
.farm-module .farm-module-footer .farm-module-foot {
  height: 100%;
  width: 35px;
}
.farm-module .farm-module-footer .module-footer-top-box {
  height: 65%;
  display: flex;
  justify-content: center;
}
.farm-module .farm-module-footer .module-footer-top {
  width: 10px;
  background-color: #D8D8D8;
}
.farm-module .farm-module-footer .module-footer-bottom {
  width: 100%;
  height: 35%;
  border-radius: 6px 6px 1px 1px;
  background-color: #262626;
}

.farm-cell {
  position: relative;
  width: 100%;
  border-radius: 3px;
  border-bottom: 16px solid #262626;
}
.farm-cell .farm-cell__states {
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: right;
  padding-right: 20px;
  z-index: 10;
  font-size: 0.8em;
}
.farm-cell .farm-cell__states:nth-child(2) {
  margin-top: 16px;
}
.farm-cell .farm-cell__states .led-inactive {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 8px;
  height: 8px;
  border: 1px solid lightgray;
  border-radius: 100%;
}
.farm-cell .farm-cell__states .led-bounce {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
}
.farm-cell .farm-cell__states .led-bounce:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.farm-cell .farm-cell__states .led-bounce:after {
  content: "";
  position: absolute;
  animation: led-bounce 1.5s ease-in-out infinite;
  border-radius: 100%;
}
.farm-cell .farm-cell__states .led-bounce.red:before, .farm-cell .farm-cell__states .led-bounce.red:after {
  background: #F9224B;
}
.farm-cell .farm-cell__states .led-bounce.blue:before, .farm-cell .farm-cell__states .led-bounce.blue:after {
  background: #B0D5EF;
}
.farm-cell .cell-content {
  overflow: hidden;
  height: 100%;
  position: relative;
  border-radius: 3px;
  background-color: #F8F8F8;
}
.farm-cell .cell-content-maintenance {
  border: 3px solid orange;
  background-color: #ffe7ba;
}
.farm-cell .cell-content-hover {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 100;
  height: calc(100% + 16px);
  width: 106%;
  top: -8px;
  left: -3%;
  border: 8px solid rgba(0, 0, 0, 0);
}
.farm-cell .cell-content-hover:hover {
  transition: border 0.2s linear;
  border: 8px solid #000000;
  cursor: pointer;
}
.farm-cell .cell-content-hover:hover .cell-content-hover-radius {
  transition: box-shadow 0.2s linear;
  box-shadow: 0 0 0 5px #b9b9b9;
}
.farm-cell .cell-content-hover-radius {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
}
.farm-cell .cell-air-extraction-block {
  position: absolute;
  z-index: 200;
  width: 100%;
  font-size: 10px;
}
.farm-cell .cell-air-extraction-position-with-color {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 4px;
}
.farm-cell .cell-air-extraction-position-without-color {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 4px;
}
.farm-cell .cell-air-extraction-on span {
  color: #ec6b22;
}
.farm-cell .cell-air-extraction-on path {
  fill: #ec6b22;
}
.farm-cell .cell-air-extraction-off > * span, .farm-cell .cell-air-extraction-off > * > * span {
  color: #8D8D8D;
}
.farm-cell .cell-air-extraction-off > * path, .farm-cell .cell-air-extraction-off > * > * path {
  fill: #8D8D8D;
}
.farm-cell .cell-air-extraction-icon {
  height: 25px;
  width: 22px;
  transform: scale(0.7);
}
.farm-cell .cell-led-light {
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -30px;
  width: 100%;
  height: 300px;
}
.farm-cell .cell-led-light-effect {
  z-index: 0;
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  height: 60%;
  width: 100%;
}
.farm-cell .cell-header {
  position: relative;
  height: 20%;
}
.farm-cell .cell-header .cell-led-line {
  z-index: 100;
  height: 7px;
  border-radius: 3px 3px 0 0;
}
.farm-cell .cell-header .cell-type {
  margin: 6px 0 0 10px;
  font-weight: bold;
}
.farm-cell .cell-body {
  position: relative;
}
.farm-cell .cell-body-printers {
  height: 80%;
  display: flex;
  align-items: end;
}
.farm-cell .cell-body-printers .cell-printers {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cell-printer {
  width: 100px;
  max-height: 75%;
  margin: 0 20px;
}
.cell-printer .printer-header {
  display: flex;
  justify-content: center;
}
.cell-printer .printer-header .printer-header-content-big {
  height: 17px;
}
.cell-printer .printer-header .printer-header-content-small {
  height: 17px;
}
.cell-printer .printer-header .printer-header-content-xs {
  height: 14px;
}
.cell-printer .printer-header .printer-header-content {
  width: 80%;
  background-color: #1D1D1D;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #CCC;
  font-size: 10px;
}
.cell-printer .printer-body-big {
  height: 160px;
}
.cell-printer .printer-body-small {
  height: 110px;
}
.cell-printer .printer-body-xs {
  height: 70px;
}
.cell-printer .printer-body {
  background-color: #484848;
}
.cell-printer .printer-body .printer-data {
  padding-top: 8px;
}
.cell-printer .printer-body .printer-data, .cell-printer .printer-body .printer-data > * {
  color: white;
  text-align: center;
}
.cell-printer .printer-body .printer-name {
  padding: 0 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
}
.cell-printer .printer-body .printer-name-big {
  font-size: 12px;
}
.cell-printer .printer-body .printer-name-small {
  font-size: 11px;
}
.cell-printer .printer-body .printer-name-xs {
  font-size: 11px;
}
.cell-printer .printer-body .printer-brand {
  font-size: 10px;
  color: #CCC;
}
.cell-printer .printer-body .printer-status-block {
  display: flex;
  justify-content: center;
}
.cell-printer .printer-body .printer-status-big {
  height: 70px;
  width: 74px;
  border-radius: 100%;
  margin-top: 18px;
}
.cell-printer .printer-body .printer-status-small {
  height: 70px;
  width: 74px;
  border-radius: 100%;
  margin-top: 1px;
}
.cell-printer .printer-body .printer-status-xs {
  height: 40px;
  width: 66px;
  border-radius: 24px;
  margin-top: 4px;
}
.cell-printer .printer-body .printer-status {
  background-color: #1D1D1D;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cell-printer .printer-body .printer-status-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
}
.cell-printer .printer-body .printer-status-icon > span {
  font-size: 18px;
}
.cell-printer .printer-body .printer-status-value {
  display: flex;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 10px;
}
.cell-printer .printer-manual-mode {
  margin-top: 4px;
  display: flex;
  justify-content: center;
  font-size: 10px;
  color: #CCCCCC;
}
.cell-printer .printer-status-manual-mode {
  font-size: 10px;
  color: #CCCCCC;
  margin-top: -2px;
  display: flex;
  justify-content: center;
}

.cell-material {
  display: flex;
  justify-content: center;
}
.cell-material .material-status-block-big {
  margin-top: -10px;
  height: 140px;
}
.cell-material .material-status-block-small {
  margin-top: -26px;
  height: 100px;
}
.cell-material .material-status-block-xs {
  margin-top: -25px;
  height: 77px;
}
.cell-material .material-status-value {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
  align-items: center;
}
.cell-material .material-status-value > div {
  color: #f5f5f5;
}
.cell-material .material-status-value-swirl-big {
  top: -78px;
}
.cell-material .material-status-value-swirl-small {
  top: -70px;
}
.cell-material .material-status-value-swirl-xs {
  top: -60px;
}
.cell-material .material-status-value-regeneration-big {
  top: -60px;
}
.cell-material .material-status-value-regeneration-small {
  top: -50px;
}
.cell-material .material-status-value-regeneration-small > div {
  font-size: 10px;
}
.cell-material .material-status-value-regeneration-xs {
  top: -38px;
}
.cell-material .material-status-value-regeneration-xs > div {
  font-size: 9px;
}
.cell-material .cell-material-content {
  width: 100%;
}
.cell-material .material-scale {
  display: flex;
  justify-content: center;
}
.cell-material .material-scale-big {
  margin-top: 14px;
}
.cell-material .material-scale-small {
  margin-top: 18px;
}
.cell-material .material-scale-xs {
  margin-top: 13px;
}
.cell-material .material-scale-button {
  border: 1px solid #1D1D1D;
  z-index: 200;
}
.cell-material .material-scale-button:hover {
  background-color: #1D1D1D;
  color: white;
}
.cell-material .material-scale-button-big {
  padding: 6px 16px;
}
.cell-material .material-scale-button-small {
  padding: 3px 16px;
}
.cell-material .material-scale-button-xs {
  padding: 0 16px;
}
.cell-material .material-round {
  display: flex;
  justify-content: center;
}
.cell-material .material-round-xs {
  padding-top: 4px;
}
.cell-material .material-status-value-inactive {
  display: flex;
  justify-content: center;
}
.cell-material .material-status-value-inactive-value {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #484848;
  color: #CCCCCC;
  border-radius: 100%;
}
.cell-material .material-status-value-inactive-value-big {
  margin-bottom: 10px;
  width: 112px;
  height: 112px;
}
.cell-material .material-status-value-inactive-value-small {
  margin-top: -12px;
  margin-bottom: -8px;
  width: 95px;
  height: 95px;
}
.cell-material .material-status-value-inactive-value-xs {
  margin-top: -15px;
  margin-bottom: -8px;
  width: 77px;
  height: 77px;
}
.cell-material .material-off {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}
.cell-material .material-off-big {
  width: 130px;
  height: 130px;
}
.cell-material .material-off-small {
  width: 75px;
  height: 75px;
}
.cell-material .material-off-xs {
  width: 45px;
  height: 45px;
}
.cell-material .round-spinner {
  display: inline-block;
  position: relative;
}
.cell-material .round-spinner-big {
  width: 120px;
  height: 120px;
}
.cell-material .round-spinner-small {
  width: 100px;
  height: 100px;
}
.cell-material .round-spinner-xs {
  width: 80px;
  height: 80px;
}
.cell-material .round-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 8px 0;
  border: 8px solid #F9224B;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #F9224B transparent transparent transparent;
  background-color: #1D1D1D;
  background-clip: content-box;
}
.cell-material .round-spinner-big div {
  width: 120px;
  height: 120px;
}
.cell-material .round-spinner-small div {
  width: 100px;
  height: 100px;
}
.cell-material .round-spinner-xs div {
  width: 80px;
  height: 80px;
}
.cell-material .round-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.cell-material .round-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.cell-material .round-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cell-material .round-pulse {
  background: #28A5FF;
  border-radius: 50%;
  margin: 10px;
  box-shadow: 0 0 0 0 black;
  transform: scale(1);
  animation: pulse 2s infinite;
}
.cell-material .round-pulse-big {
  height: 120px;
  width: 120px;
}
.cell-material .round-pulse-small {
  height: 100px;
  width: 100px;
}
.cell-material .round-pulse-xs {
  height: 80px;
  width: 80px;
  margin-top: 0;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(40, 165, 255, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(40, 165, 255, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(40, 165, 255, 0);
  }
}

.control-panel .control-panel-block {
  display: flex;
  align-items: center;
}
.control-panel .control-panel-block .control-panel-screen {
  height: 116px;
  width: 200px;
  background-color: #1D1D1D;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.control-panel .control-panel-block .control-panel-content-block {
  display: flex;
  justify-content: space-between;
  background-color: #F8F8F8;
  border-radius: 5px;
  height: 100px;
  width: 184px;
  border: 4px solid #F8F8F8;
}
.control-panel .control-panel-block .control-panel-content-block:hover {
  transition: border 0.2s linear;
  border: 4px solid #b9b9b9;
  cursor: pointer;
}
.control-panel .control-panel-block .control-panel-content {
  width: 100%;
}
.control-panel .control-panel-block .control-panel-text {
  font-weight: bold;
  margin: 10px;
}
.control-panel .control-panel-block .control-panel-icon {
  margin: 10px;
  font-size: 20px;
}
.control-panel .control-panel-block .control-panel-buzzer-button-block {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.control-panel .control-panel-block .control-panel-link {
  margin-bottom: 40px;
  background-color: #1D1D1D;
  width: 40px;
  height: 22px;
}
.control-panel .control-panel-block .stop-buzzer-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.control-panel .control-panel-block .stop-buzzer-button {
  padding: 5px;
  background-color: #1D1D1D;
  width: 40px;
  height: 40px;
  border-radius: 0 0 5px 5px;
}
.control-panel .control-panel-block .stop-buzzer-button-content-icon {
  display: flex;
  justify-content: center;
}
.control-panel .control-panel-block .stop-buzzer-icon {
  border-radius: 100%;
  background-color: #F9224B;
  height: 25px;
  width: 25px;
  border: 3px solid #FFEA32;
}

.farm__dialog_common label {
  width: auto !important;
  margin: 0 !important;
}
.farm__dialog_common .toggle-label-right {
  display: flex;
  align-items: center;
}
.farm__dialog_common .farm-command-line {
  margin-bottom: 5px;
}
.farm__dialog_common .toggle-label {
  margin-left: 6px;
  font-weight: bold;
}
.farm__dialog_common .button-danger {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.farm__dialog_common .button-danger .button-icon-left {
  margin-right: 8px;
}
.farm__dialog_common .button-danger:not([disabled]) span {
  transition: all 0.2s ease-in-out;
  color: #F9224B;
}
.farm__dialog_common .button-danger:not([disabled]):hover {
  background: #F9224B;
}
.farm__dialog_common .button-danger:not([disabled]):hover span {
  transition: all 0.2s ease-in-out;
  color: white;
}
.farm__dialog_common .button-danger:not([disabled]):hover strong {
  color: white;
}

.farm__dialog_farm_manager {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.farm__dialog_farm_manager .buzzer-button {
  margin-top: 15px;
  padding: 4px 8px;
}
.farm__dialog_farm_manager .scanner-toggle {
  margin-top: 10px;
}

.farm__dialog_maintenance .maintenance-infos {
  margin-top: 10px;
}
.farm__dialog_maintenance .maintenance-infos .maintenance-infos-label {
  margin-right: 6px;
}
.farm__dialog_maintenance .maintenance-infos .maintenance-infos-date {
  font-weight: bold;
}
.farm__dialog_maintenance .maintenance-confirm-error {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.farm__dialog_maintenance .maintenance-confirm-error .maintenance-confirm-error-icon {
  color: #F9224B;
  margin-right: 5px;
}
.farm__dialog_maintenance .maintenance-confirm-error span {
  color: #F9224B;
}
.farm__dialog_maintenance .maintenance-confirm-success {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.farm__dialog_maintenance .maintenance-confirm-success .maintenance-confirm-success-icon {
  color: #2CEAA8;
  margin-right: 5px;
}
.farm__dialog_maintenance .maintenance-confirm-success span {
  color: #2CEAA8;
}

.farm__dialog_maintenance_history {
  margin-top: 15px;
}
.farm__dialog_maintenance_history .maintenances-history-title {
  font-weight: bold;
  font-size: 14px;
}
.farm__dialog_maintenance_history .maintenances-history-body {
  margin-top: 8px;
}
.farm__dialog_maintenance_history .maintenances-history-table {
  overflow-y: auto;
  max-height: 150px;
}

.outlined-card {
  padding: 15px 30px;
  background: white;
  border-radius: 10px;
  margin: 30px;
}

.farm-header {
  margin-bottom: 30px;
}

.no-environment-dialog {
  margin: 15px;
}
