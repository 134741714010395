@charset "UTF-8";
#main__projects #projects__archived-projects-list .projects-list__archived-project-item .project-item__title h2, #main__projects #projects__projects-list .projects-list__project-item .project-item__title h2 {
  font-size: 18px;
  margin: 0;
  margin-bottom: 7px;
  color: #444;
}

#main__project #project__figures #figures__print-data div p:last-child {
  text-align: center;
  border-radius: 10px;
  padding: 7px 15px;
  font-size: 10px;
  display: inline-block;
  margin-top: 7px;
  background: white;
}

#main__project #project__infos #infos__data-progression, #main__projects #projects__projects-list .projects-list__project-item .project-item__data-progression {
  display: flex;
}
#main__project #project__infos #infos__data-progression .data-progression__date, #main__projects #projects__projects-list .projects-list__project-item .project-item__data-progression .data-progression__date {
  width: 25%;
  text-align: left;
}
#main__project #project__infos #infos__data-progression .data-progression__date p, #main__projects #projects__projects-list .projects-list__project-item .project-item__data-progression .data-progression__date p {
  margin: 0;
}
#main__project #project__infos #infos__data-progression .data-progression__date .date__element, #main__projects #projects__projects-list .projects-list__project-item .project-item__data-progression .data-progression__date .date__element {
  text-align: center;
  border-radius: 10px;
  padding: 7px 15px;
  font-size: 10px;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 7px;
}
#main__project #project__infos #infos__data-progression .data-progression__date .date__element.date__element--start-date, #main__projects #projects__projects-list .projects-list__project-item .project-item__data-progression .data-progression__date .date__element.date__element--start-date {
  background: #bdd1f9;
}
#main__project #project__infos .project__tag, #main__projects #projects__archived-projects-list .projects-list__archived-project-item .project-item__tag, #main__projects #projects__projects-list .projects-list__project-item .project-item__tag {
  font-size: 12px;
  color: #444;
  background: #f5f5f5;
  padding: 4px 8px 5px;
  border-radius: 10px;
  margin-right: 7px;
  cursor: default;
  user-select: none;
  display: inline-block;
}

.project__member {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 1000px;
  color: white !important;
  font-weight: bold;
  user-select: none;
  cursor: default;
}
.project__member.member--admin {
  background: #00CAC0;
  color: white !important;
}
.project__member.member--admin:after {
  content: "★";
}
.project__member.member--unsubscribed {
  text-decoration: line-through;
  background: lightgray;
  color: #f5f5f5 !important;
}

#main__projects #projects__projects-list {
  display: flex;
  flex-wrap: wrap;
}
#main__projects #projects__projects-list .projects-list__project-item {
  display: flex;
  flex-direction: column;
  background: #F8F8F8;
  border-radius: 4px;
  text-decoration: none;
  width: 500px;
  margin: 0 30px 30px 0;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}
#main__projects #projects__projects-list .projects-list__project-item, #main__projects #projects__projects-list .projects-list__project-item * {
  color: #444;
}
#main__projects #projects__projects-list .projects-list__project-item a.button-link {
  color: white;
}
#main__projects #projects__projects-list .projects-list__project-item:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0 7px 15px;
  transform: scale(1.03);
}
#main__projects #projects__projects-list .projects-list__project-item .project-item__zone {
  padding: 15px 30px;
  border-bottom: 1px solid white;
}
#main__projects #projects__projects-list .projects-list__project-item .project-item__zone:last-child {
  border: none;
}
#main__projects #projects__projects-list .projects-list__project-item .project-item__zone:last-child a.button-link {
  margin: 15px;
}
#main__projects #projects__projects-list .projects-list__project-item .project-item__sub-title {
  font-variant: small-caps;
}
#main__projects #projects__projects-list .projects-list__project-item .project-item__progress {
  width: 100px;
  height: 100px;
  float: left;
}
#main__projects #projects__projects-list .projects-list__project-item .project-item__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  padding-left: 30px;
}
#main__projects #projects__projects-list .projects-list__project-item .project-item__description {
  font-size: 14px;
  text-align: justify;
  margin: 30px auto;
  background: white;
  padding: 15px;
  border-radius: 4px;
}
#main__projects #projects__projects-list .projects-list__project-item .project-item__misc-data {
  display: flex;
}
#main__projects #projects__projects-list .projects-list__project-item .project-item__misc-data div {
  margin-right: 30px;
}
#main__projects #projects__projects-list .projects-list__project-item .project-item__misc-data div .project-item__sub-title {
  margin: 0;
  margin-bottom: 7px;
}
#main__projects #projects__projects-list .projects-list__project-item .project-item__data {
  font-size: 14px;
  color: #444;
  background: white;
  padding: 7px 15px;
  border-radius: 10px;
  display: inline-block;
  margin: 0;
}
#main__projects #projects__projects-list #projects__create {
  background-image: url("../../images/icons/common/add-big.png");
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 350px;
}
#main__projects #projects__projects-list #projects__create p {
  margin-top: 150px;
}
#main__projects #projects__archived-projects-list {
  display: flex;
  flex-wrap: wrap;
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item {
  display: flex;
  flex-direction: column;
  background: #F8F8F8;
  border-radius: 4px;
  text-decoration: none;
  width: 500px;
  margin: 15px 30px 30px 0;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item, #main__projects #projects__archived-projects-list .projects-list__archived-project-item * {
  color: #444;
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item a.button-link {
  color: white;
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0 7px 15px;
  transform: scale(1.03);
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item .project-item__zone {
  padding: 7px 15px;
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item .project-item__zone:last-child {
  border: none;
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item .project-item__zone:last-child a.button-link {
  margin: 15px;
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item .project-item__sub-title {
  font-variant: small-caps;
  margin-bottom: 7px;
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item .project-item__title {
  display: flex;
  flex-direction: row;
  justify-content: left;
  height: 45px;
  padding: 15px 0 15px 15px;
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item .project-item__title .project-state {
  font-size: 12px;
  color: white;
  padding: 3.5px 7px;
  border-radius: 10px;
  user-select: none;
  width: fit-content;
  margin-left: 7px;
  height: fit-content;
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item .project-item__title .project-state.project-state--archived {
  background: gray;
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item .project-item__description {
  font-size: 14px;
  text-align: justify;
  margin: auto;
  margin-top: 7px;
  background: #f5f5f5;
  padding: 15px;
  border-radius: 10px;
}
#main__projects #projects__archived-projects-list .projects-list__archived-project-item .project-item__data {
  font-size: 14px;
  color: #444;
  background: #f5f5f5;
  padding: 7px 15px;
  border-radius: 10px;
  display: inline-block;
  margin: 0;
}

#main__create-project label {
  margin-left: 0;
}
#main__create-project input, #main__create-project select {
  margin-left: 0;
  margin-bottom: 15px;
  display: block;
}

#main__project .block {
  margin-top: 0;
  margin-bottom: 15px;
}
#main__project .block:first-child {
  margin-right: 7.5px;
}
#main__project .block:last-child {
  margin-left: 7.5px;
}
#main__project #project__infos #infos__project-name {
  font-size: 18px;
}
#main__project #project__infos #infos__project-name .project-state {
  font-size: 12px;
  color: white;
  padding: 3.5px 7px;
  border-radius: 10px;
  user-select: none;
  width: fit-content;
}
#main__project #project__infos #infos__project-name .project-state.project-state--archived {
  background: gray;
}
#main__project #project__infos .project__color {
  width: 20px;
  height: 20px;
  margin-bottom: -4px;
  margin-right: 7px;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
}
#main__project #project__infos #infos__data-progression {
  margin-top: 30px;
  margin-bottom: 15px;
}
#main__project #project__infos #infos__data-progression .data-progression__date {
  width: 50%;
}
#main__project #project__infos #infos__data-progression .data-progression__date .date__element {
  background-color: white;
}
#main__project #project__infos .project__tag {
  margin-top: 7px;
}
#main__project #project__infos .project__member {
  margin-top: 7px;
  margin-bottom: 15px;
}
#main__project #project__infos input[type=submit], #main__project #project__infos button {
  margin: 0;
  margin-right: 7px;
}
#main__project #project__infos #infos__members #members__add-form {
  display: flex;
  align-items: center;
}
#main__project #project__infos #infos__members #members__add-form input[type=text] {
  width: 100%;
}
#main__project #project__infos #infos__members #members__add-form input[type=submit] {
  min-width: 100px;
  padding-left: 0;
  padding-right: 0;
}
#main__project #project__notes p {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
}
#main__project #project__figures #figures__global-progression {
  text-align: center;
  display: flex;
  justify-content: space-between;
}
#main__project #project__figures #figures__global-progression .global-progression__block {
  border-radius: 10px;
  background: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
}
#main__project #project__figures #figures__global-progression .global-progression__block h3 {
  font-size: 16px;
  margin-bottom: 15px;
}
#main__project #project__figures #figures__global-progression .global-progression__block .circular {
  position: relative;
}
#main__project #project__figures #figures__global-progression .global-progression__block .circular svg * {
  stroke-linecap: round;
  animation-duration: 1000ms;
}
#main__project #project__figures #figures__global-progression .global-progression__block .circular .bg {
  position: absolute;
  top: 0;
  left: 4px;
  width: 135px;
  height: 135px;
  line-height: 110px;
  border: 12px solid #f5f5f5;
  border-radius: 100%;
  font-size: 30px;
}
#main__project #project__figures #figures__global-progression .global-progression__block .block__stat {
  font-size: 30px;
}
#main__project #project__figures #figures__global-progression #global-progression__circle {
  margin: 15px auto;
  width: 150px;
  height: 150px;
}
#main__project #project__figures #figures__print-data div {
  width: 50%;
  text-align: center;
}
#main__project #project__figures #figures__print-data div p:last-child {
  font-size: 16px;
}
#main__project #project__pieces #pieces__listing {
  display: flex;
}
#main__project #project__pieces #pieces__listing .listing__piece-item {
  display: block;
  width: 170px;
  height: 100px;
  margin-right: 7px;
  position: relative;
}
#main__project #project__pieces #pieces__listing .listing__piece-item a {
  background: white;
  display: block;
  height: 100%;
  text-decoration: none;
  padding: 15px;
  border-radius: 10px;
  border-left: 10px solid transparent;
  transition: all 0.2s ease-in-out;
}
#main__project #project__pieces #pieces__listing .listing__piece-item a:hover {
  border-color: #00CAC0;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0 7px 15px;
  transform: scale(1.03);
}
#main__project #project__pieces #pieces__listing .listing__piece-item .piece__name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
}
#main__project #project__pieces #pieces__listing .listing__piece-item .piece__quantity {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 12px;
  background: #00CAC0;
  color: white;
  position: absolute;
  bottom: 7px;
  right: 7px;
  border-radius: 1000px;
}
#main__project #project__files {
  margin-left: 0;
}
#main__project #project__files #files__list .files-list__file-item {
  display: inline-block;
  width: 300px;
  text-decoration: none;
  padding: 15px 30px;
  padding-left: 50px;
  background: white;
  background-image: url("../../images/icons/drop/attached_file.png");
  background-repeat: no-repeat;
  background-position: 15px center;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-right: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}
#main__project #project__files #files__list .files-list__file-item:hover {
  transform: scale(1.03);
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px;
}

#main__edit-project label {
  margin-left: 0;
}
#main__edit-project input, #main__edit-project select {
  margin-left: 0;
  margin-bottom: 15px;
  display: block;
}

.project__pic_table .rs-table .rs-table-row:not(.rs-table-row-header),
.project__pic_table .rs-table .rs-table-row:not(.rs-table-row-header) .rs-table-cell-group,
.project__pic_table .rs-table .rs-table-row:not(.rs-table-row-header) .rs-table-cell {
  background-color: #F0F0F0;
}
.project__pic_table .rs-table-row {
  border-bottom: 3px solid white !important;
}
.project__pic_table .pic-table-title {
  margin-bottom: 5px;
}
.project__pic_table .table-status {
  font-size: 12px;
  color: white;
  padding: 0 7px;
  border-radius: 10px;
  cursor: default;
  user-select: none;
  width: fit-content;
}
.project__pic_table .table-status.table-status-draft {
  background-color: #6C757D;
}
.project__pic_table .table-status.table-status-active {
  background-color: #2CEAA8;
}
.project__pic_table .pic-table-edit-button {
  background-color: unset;
  display: flex;
  align-items: center;
  padding: 0;
}
.project__pic_table .pic-table-edit-button:hover {
  background-color: unset;
}
.project__pic_table .pic-table-edit-button:hover span {
  color: #00BDB3;
}
.project__pic_table .pic-table-edit-button span {
  color: #00CAC0;
}
.project__pic_table .pic-table-edit-button .pic-table-edit-button-icon {
  font-size: 18px;
  margin-right: 5px;
}
.project__pic_table .pic-table-edit-button .pic-table-edit-button-label {
  font-size: 12px;
}
