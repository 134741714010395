.main__table .title {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
}
.main__table .flex {
  display: flex;
  justify-content: space-between;
}
.main__table h2 {
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  width: auto;
  color: #333333;
}
.main__table .rs-table-row:hover {
  cursor: pointer;
}
.main__table .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
.main__table .rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.main__table .rs-table-hover .rs-table-row:hover .rs-table-cell {
  background-color: #f5f5f5;
}
.main__table .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
.main__table .rs-table-hover .rs-table-row-header:hover .rs-table-cell {
  background-color: unset;
}
.main__table .rs-table-cell-header-icon-sort {
  fill: #00CAC0;
}
.main__table .search-bar {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
  width: 40%;
}
.main__table .search-bar-input {
  width: 100%;
}
.main__table .search-bar-addon-icon {
  height: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 20px !important;
}

.main-page__pic {
  height: 100%;
  overflow-y: auto;
  background: white;
}
.main-page__pic .main__pic-main button:hover {
  background: unset;
}
.main-page__pic .main__pic-main .menu-bar {
  z-index: 1201;
  height: 64px;
  background: #F8F8F8;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14);
}
.main-page__pic .main__pic-main .nav-bar-end {
  align-items: center;
  display: flex;
  position: absolute;
  right: 16px;
}
.main-page__pic .main__pic-main .nav-bar-end .saving {
  width: 200px;
  text-align: center;
  font-size: 14px;
}
.main-page__pic .main__pic-main .nav-bar-end .new-button {
  height: 32px;
  width: 100px;
  padding: 0;
  margin-right: 16px;
  color: #00CAC0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  border: 1.5px solid #00CAC0;
  box-sizing: border-box;
  border-radius: 3px;
  background: #F8F8F8;
}
.main-page__pic .main__pic-main .edit-pic-name__form {
  margin-bottom: 80px;
}
.main-page__pic .main__pic-main .edit-pic-name__form input:focus {
  box-shadow: unset;
  transform: unset;
}
.main-page__pic .main__pic-main .add-card-line-icone {
  position: relative;
  left: 50%;
}
.main-page__pic .main__pic-main .add-card-button-icone {
  bottom: 25px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.main-page__pic .main__pic-main .add-card-button-icone g {
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px #2222221A;
}
.main-page__pic .main__pic-main .card {
  width: 850px;
  padding: 0;
}
.main-page__pic .main__pic-main .card-content {
  padding: 0;
}
.main-page__pic .main__pic-main .card-content:last-child {
  padding-bottom: 0;
}
.main-page__pic .main__pic-main .card-container {
  width: fit-content;
}
.main-page__pic .main__pic-main .card-container h2 {
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0;
  align-items: center;
  letter-spacing: 0.15px;
}
.main-page__pic .main__pic-main .card-container h3 {
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
}
.main-page__pic .main__pic-main .card-container p {
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #8D8D8D;
  margin: 0;
}
.main-page__pic .main__pic-main .card-container p strong {
  font-family: Cabin;
}
.main-page__pic .main__pic-main .card-container .card-icone {
  padding: 5px;
  width: 71px !important;
  border: 0.5px solid #00CAC0;
  box-sizing: border-box;
  border-radius: 8px;
}
.main-page__pic .main__pic-main .card-container .card-title {
  margin-left: 9px;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.main-page__pic .main__pic-main .card-container .card-title:before {
  content: attr(data-order);
}
.main-page__pic .main__pic-main .card-container .icone-action {
  margin-left: auto;
}
.main-page__pic .main__pic-main .card-container .icone-action path:last-child {
  fill: #8D8D8D;
}
.main-page__pic .main__pic-main .card-container .icone-action:hover {
  cursor: pointer;
}
.main-page__pic .main__pic-main .card-container .card-body {
  display: flex;
  min-height: 408px;
  background: #F8F8F8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #CCCCCC;
  border-radius: 0px 0px 4px 4px;
}
.main-page__pic .main__pic-main .card-container .card-body.disable {
  display: none !important;
}
.main-page__pic .main__pic-main .card-container .card-body .cards-list {
  overflow-y: auto;
  display: flex;
  width: 70%;
  flex-wrap: wrap;
  padding: 30px;
  align-content: flex-start;
}
.main-page__pic .main__pic-main .card-container .card-body .cards-list .search-bar {
  background: white;
  height: 40px;
  margin-bottom: 30px;
  width: 100%;
}
.main-page__pic .main__pic-main .card-container .card-body .cards-list .search-bar .search-icone {
  margin-left: 16px;
  fill: #6C757D;
}
.main-page__pic .main__pic-main .card-container .card-body .cards-list .search-bar:before {
  border: none;
}
.main-page__pic .main__pic-main .card-container .card-body .cards-list .search-bar:after {
  border: none;
}
.main-page__pic .main__pic-main .card-container .card-body .cards-list .search-bar input {
  background: white;
  color: #6C757D;
  margin: 0;
}
.main-page__pic .main__pic-main .card-container .card-body .cards-list .search-bar input:focus {
  transform: none;
  box-shadow: none;
}
.main-page__pic .main__pic-main .card-container .card-body .cards-list .card-item-container {
  flex: 50%;
}
.main-page__pic .main__pic-main .card-container .card-body .cards-list .card-item-container .card-item {
  display: flex;
  height: 50px;
  width: 260px;
  padding: 4px 4px 4px 8px;
  margin-bottom: 30px;
}
.main-page__pic .main__pic-main .card-container .card-body .cards-list .card-item-container .card-item .card-item-icone {
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
}
.main-page__pic .main__pic-main .card-container .card-body .cards-list .card-item-container .card-item:hover {
  background: #EFEFEF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  cursor: pointer;
}
.main-page__pic .main__pic-main .card-container .card-body .step-selection {
  display: grid;
  align-content: center;
}
.main-page__pic .main__pic-main .card-container .card-body .step-selection .step-icone {
  height: 80px;
  width: 80px;
  margin-left: 6px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main-page__pic .main__pic-main .card-container .card-body .step-selection .select-step {
  background: #EFEFEF;
  width: 216px;
  height: 98px;
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
}
.main-page__pic .main__pic-main .add-card-button-line-icone {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.main-page__pic .main__pic-main .add-card-button-line-icone g {
  cursor: pointer;
}
.main-page__pic .main__pic-main .menu-side-bar-icone {
  width: 40px;
  height: 40px;
  margin-top: 16px;
  margin-left: 8px;
}
.main-page__pic .main__pic-main .menu-side-bar-icone.active {
  border: 0.1px solid #00CAC0;
  box-sizing: border-box;
  border-radius: 3px;
  background: #EFEFEF;
}
.main-page__pic .main__pic-main .menu-side-bar-icone .cls-1 {
  stroke: none !important;
  fill: #555 !important;
}
.main-page__pic .main__pic-main .main__side-bar h2 {
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-left: 16px;
  color: #333333;
}
.main-page__pic .main__pic-main .main__side-bar h3 {
  font-style: normal;
  font-family: Cabin;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  letter-spacing: 0.15px;
  margin-left: 8px;
}
.main-page__pic .main__pic-main .main__side-bar .step-details ol {
  margin: 0;
}
.main-page__pic .main__pic-main .side-bar-header {
  background: #EFEFEF;
  height: 72px;
  display: flex;
  align-items: center;
}
.main-page__pic .main__pic-main .side-bar-header .side-bar-header-title {
  margin-left: 16px;
  width: 80%;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  font-family: Cabin, serif;
  font-style: normal;
  color: #333333;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.main-page__pic .main__pic-main .side-bar-header .side-bar-header-close-icone {
  margin-left: 250px;
  position: absolute;
  cursor: pointer;
}
.main-page__pic .main__pic-main .side-bar-content {
  margin-top: 30px;
}
.main-page__pic .main__pic-main .block {
  border-radius: 3px;
  width: 100%;
  margin: 0;
  margin-top: 15px;
  padding: 10px 45px;
}
.main-page__pic .main__pic-main .button-zone {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 30px;
  bottom: 0;
}
.main-page__pic .main__pic-main .button-zone button {
  width: 341px;
  height: 50px;
  border: 1px solid #555555;
  border-radius: 3px;
  margin-left: 40px;
  margin-right: 40px;
}

.main__side-bar .versions__list {
  padding: 0 15px;
}
.main__side-bar .versions__list h2 {
  margin-bottom: 15px;
}
.main__side-bar .versions__list .btn-white:hover {
  background: #00CAC0;
}
.main__side-bar .step-details {
  height: 300px;
  overflow-y: scroll;
}
.main__side-bar .step-details .sub-menu-icone {
  height: 45px;
  width: 45px;
}
.main__side-bar .step-details .MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
.main__side-bar .step-details .MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
.main__side-bar .step-details .accordion-details li {
  padding: 8px 0 8px 0;
  letter-spacing: 0.15px;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  margin-left: 45px;
}
.main__side-bar .step-details .accordion-details li.uncomplete {
  color: #CCCCCC;
}
.main__side-bar .step-details .accordion-details li .check-circle-icone {
  width: 14px;
  height: 14px;
  fill: #0DF385;
  right: 16px;
  position: absolute;
  margin-top: 5px;
}
.main__side-bar .step-details .accordion-details li .check-circle-icone.uncomplete {
  fill: #CCCCCC;
}
.main__side-bar hr {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 90%;
  border: 1px solid #CCCCCC;
}
.main__side-bar .gantt {
  display: flex;
  padding: 16px 16px 0 16px;
}
.main__side-bar .gantt .production-pre {
  height: 60px;
  background: #3E5DFF;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.main__side-bar .gantt .production-fabrication {
  height: 60px;
  background: #0B4FD3;
}
.main__side-bar .gantt .production-post {
  height: 60px;
  background: #0742B5;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.main__side-bar .separator {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #333333;
}
.main__side-bar .separator::before,
.main__side-bar .separator::after {
  content: "";
  flex: 1;
  border-bottom: 2px solid #0B4FD3;
}
.main__side-bar .separator:not(:empty)::before {
  margin-right: 8px;
}
.main__side-bar .separator:not(:empty)::after {
  margin-left: 8px;
}
.main__side-bar .production-estimation {
  position: relative;
  display: flex;
}
.main__side-bar .production-estimation p {
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #333333;
  margin-left: 17px;
}
.main__side-bar .production-estimation p .info-icone {
  width: 15px;
  height: 15px;
  fill: #CCCCCC !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: 3px;
}
.main__side-bar .production-estimation p.estimation {
  position: absolute;
  right: 16px;
  font-weight: bold;
}
.main__side-bar .button-production {
  position: relative;
  display: flex;
  width: 90%;
  height: 40px;
  margin: 0 16px 0 16px;
  border-radius: 3px;
  background: #00CAC0;
  justify-content: center;
}
.main__side-bar .button-production h3 {
  color: white !important;
  font-weight: 500;
  margin: 0;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main__side-bar .button-production .production-icone {
  height: 25px;
  width: 25px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main__card-platform {
  width: 100%;
  padding: 9px 6px 9px 6px;
}
.main__card-platform .drag-drop-zone {
  border: 0.8px dashed #00CAC0;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.main__card-platform .drag-drop-zone .full-zone {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main__card-platform .drag-drop-zone .full-zone button {
  background: #00CAC0;
  color: white;
  border-radius: 3px;
  border: none;
  padding: 5px 9px 5px 9px;
  margin-top: 18px;
  margin-bottom: 23px;
}
.main__card-platform .drag-drop-zone .full-zone a {
  color: #00CAC0;
}
.main__card-platform .drag-drop-zone.reduced {
  height: 48px;
}
.main__card-platform .drag-drop-zone.reduced div {
  display: inline-flex;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main__card-platform .drag-drop-zone.reduced div p {
  margin-left: 3px;
}
.main__card-platform .drag-drop-zone.reduced div .add-pieces-icone {
  width: 24px;
  height: auto;
  margin-right: 20px;
}
.main__card-platform .drag-drop-zone button {
  display: inline-block;
  width: 40%;
}
.main__card-platform .drag-drop-zone button input {
  display: none;
}
.main__card-platform .drag-drop-zone button div {
  position: relative;
  left: 150px;
  bottom: 30px;
}
.main__card-platform .drag-drop-zone div.progressAttachment {
  display: inline-block;
  width: 40%;
}
.main__card-platform .list-files {
  position: relative;
  margin-top: 12px;
  background: white;
  width: 844px;
  height: 255px;
  overflow-y: auto;
  border-radius: 3px;
}
.main__card-platform .list-files table {
  border-radius: 0;
  padding: 12px 20px 12px 20px;
  border-spacing: 0 12px;
}
.main__card-platform .list-files table th {
  padding-bottom: 0;
}
.main__card-platform .list-files table tr {
  border-radius: 3px;
}
.main__card-platform .list-files table tr td {
  border-bottom: 1px solid #D8D8D8 !important;
  border-top: 1px solid #D8D8D8 !important;
  padding: 0;
  padding-left: 30px;
  height: 38px;
}
.main__card-platform .list-files table tr td:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left: 1px solid #D8D8D8 !important;
}
.main__card-platform .list-files table tr td:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-right: 1px solid #D8D8D8 !important;
}
.main__card-platform .list-files table input {
  border-radius: 3px;
  width: 70px;
  text-align: center;
}
.main__card-platform .list-files p {
  bottom: 15px;
  left: 24px;
  position: absolute;
}
.main__card-platform .button-zone {
  display: flex;
}

.main__card-slicer {
  width: 100%;
  height: 100%;
  padding: 9px 6px 9px 6px;
}
.main__card-slicer form {
  height: auto;
}
.main__card-slicer input[type=time]::-webkit-calendar-picker-indicator {
  background: none;
}
.main__card-slicer input[type=time] {
  text-align: center;
}
.main__card-slicer input::-webkit-outer-spin-button,
.main__card-slicer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.main__card-slicer input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
  text-align: center;
}
.main__card-slicer .block img.enable {
  cursor: pointer;
}
.main__card-slicer .MuiTextField-root {
  margin-bottom: 15px;
}
.main__card-slicer .form-control-select {
  width: 100%;
  margin-bottom: 15px;
}
.main__card-slicer .label-select {
  padding-left: 13px;
  padding-right: 13px;
  top: -10.5px;
}
.main__card-slicer .select-multiple > div {
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}
.main__card-slicer .select-multiple > .MuiSelect-icon {
  margin-right: 8px;
}
.main__card-slicer .form-control-select > .MuiInputLabel-shrink {
  transform: translate(0, 5.5px) scale(0.75) !important;
  transform-origin: top left;
}
.main__card-slicer .form-control-select > .label-select.MuiInputLabel-shrink {
  padding-left: 18px;
}
.main__card-slicer .machine-manufacturer-connected {
  margin-top: -15px !important;
  margin-bottom: 15px !important;
  margin-left: 2px !important;
  color: #00CAC0 !important;
}
.main__card-slicer .machine-manufacturer-connected svg > path {
  fill: #00CAC0;
}
.main__card-slicer .select-build-button {
  background-color: #00CAC0 !important;
  color: white;
  margin-bottom: 20px;
}

.printers-select-text-volume {
  color: #8D8D8D;
  font-weight: 100;
  font-size: 14px;
}

.printers-select-text-name {
  font-weight: 400;
}

.printers-select-text-connected {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 13px;
}

.card-slicer__list-item-warning-message, .card-slicer__list-item-warning-message > svg > * {
  color: #ec6b22;
  fill: #ec6b22 !important;
}

.selected-build-description {
  font-size: 14px !important;
  color: black !important;
  font-weight: bold !important;
}

.selected-build {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.selected-build-delete-button {
  background-color: unset !important;
}

.selected-build-error {
  color: #F9224B !important;
  font-size: 14px !important;
  margin-top: -10px !important;
  margin-bottom: 15px !important;
}
.selected-build-error svg > path {
  fill: #F9224B;
}
.selected-build-error span {
  color: #F9224B !important;
}

.printing-data-mode-selected {
  outline: #00CAC0 2px solid;
}

.checkbox-is-fiber {
  margin-bottom: 16px;
}

.farm-profile-edit-button {
  background-color: #00CAC0 !important;
  display: flex;
  align-items: center;
}
.farm-profile-edit-button span {
  color: white;
}
.farm-profile-edit-button span.material-icons {
  font-size: 18px;
  margin-right: 5px;
}
.farm-profile-edit-button:hover {
  background-color: #00BDB3 !important;
}

.main__card_slicer_build_table {
  width: 883px;
}
.main__card_slicer_build_table .button-load-builds {
  padding: 4px;
}

.main__shedule_pic {
  width: 1100px;
  height: 628px;
}
.main__shedule_pic h1 {
  margin: auto;
  margin-right: 86px;
  font-family: Cabin;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #333333;
}
.main__shedule_pic h2 {
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  width: auto;
  color: #8D8D8D;
}
.main__shedule_pic h3 {
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
  color: #333333;
}
.main__shedule_pic p {
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #8D8D8D;
  margin: 0;
}
.main__shedule_pic p strong {
  font-family: Cabin;
}
.main__shedule_pic .button--with-icon {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.main__shedule_pic .pic-selection > * {
  vertical-align: baseline;
}
.main__shedule_pic .pic-selection {
  height: 180px;
  width: 100%;
  display: block;
  position: relative;
}
.main__shedule_pic .pic-selection select {
  font-family: Cabin;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #333333;
  background: white;
  padding: 0;
  margin: 0;
  width: 245px;
}
.main__shedule_pic .pic-selection select optgroup, .main__shedule_pic .pic-selection select option {
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #333333;
}
.main__shedule_pic .pic-selection p {
  margin: auto;
  margin-left: 0;
}
.main__shedule_pic .pic-selection .new-button {
  height: 32px;
  width: 100px;
  padding: 0;
  right: 0;
  position: absolute;
  margin-right: 16px;
  color: #00CAC0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  border: 1.5px solid #00CAC0;
  box-sizing: border-box;
  border-radius: 3px;
  background: #F8F8F8;
}
.main__shedule_pic .pic-details > * {
  vertical-align: middle;
}
.main__shedule_pic .pic-details {
  display: flex;
  height: 180px;
}
.main__shedule_pic .pic-details p {
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #8D8D8D;
}
.main__shedule_pic .pic-details table td.header {
  width: 125px;
  height: fit-content;
  padding-right: 32px;
  text-align: right;
}
.main__shedule_pic .pic-details table td {
  padding: 0;
  width: 150px;
}
.main__shedule_pic .pic-details table td .pic-quantity {
  display: inline-flex;
  border-radius: 3px;
  border: 1px solid #CCCCCC;
}
.main__shedule_pic .pic-details table td .pic-quantity img {
  cursor: pointer;
}
.main__shedule_pic .pic-details table td input {
  background: #EFEFEF;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border-radius: 0;
  transition: none;
  box-shadow: none;
  text-align: center;
  border-left-width: 0;
  font-family: Cabin;
}
.main__shedule_pic .pic-details table td input::-webkit-outer-spin-button,
.main__shedule_pic .pic-details table td input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.main__shedule_pic hr {
  border: 1px solid #C4C4C4;
}
.main__shedule_pic .message-zone {
  width: 100%;
  height: 48px;
  background: #ec6b22;
  border-radius: 3px;
  margin: auto;
  display: flex;
}
.main__shedule_pic .message-zone img {
  width: 20px;
  height: 20px;
  margin: auto;
  margin-left: 20px;
  margin-right: 24px;
}
.main__shedule_pic .message-zone h3 {
  margin: auto 0;
}
.main__shedule_pic .schedule-mode-zone {
  margin-top: 24px;
}
.main__shedule_pic .schedule-mode-zone label {
  margin-left: 40px;
  width: 100%;
  display: flex;
  margin: 0;
  vertical-align: middle;
}
.main__shedule_pic .schedule-mode-zone label span {
  height: fit-content;
  width: 24px;
  margin-right: 15px;
  background: white;
  border: 2px solid;
}
.main__shedule_pic .schedule-mode-zone label div {
  width: 100%;
}
.main__shedule_pic .schedule-mode-zone label h3 {
  width: 100%;
}
.main__shedule_pic .schedule-mode-zone input[type=checkbox]:checked + label > span {
  background: white;
}
.main__shedule_pic .schedule-mode-zone input[type=checkbox]:checked + label > span:after {
  background: #00CAC0;
  content: "";
  border-radius: 1000px;
  width: 12px;
  height: 12px;
  transition: all 0.2s ease-in-out;
}
.main__shedule_pic .schedule-mode-zone input[type=checkbox] + label > span:after {
  content: "";
  width: 12px;
  height: 12px;
  transition: all 0.2s ease-in-out;
}
.main__shedule_pic .schedule-mode-zone .schedule-mode-selection {
  margin-left: 32px;
  margin-top: 32px;
}
.main__shedule_pic .data-time-picker {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 16px;
  border-radius: 5px;
  border: 1px dashed #CCCCCC;
}
.main__shedule_pic .schedule-period {
  position: relative;
  display: inline-flex;
  width: 100%;
}
.main__shedule_pic .schedule-period table {
  width: 200px;
}
.main__shedule_pic .schedule-period table td.header {
  width: 60px;
}
.main__shedule_pic .schedule-period table td {
  padding: 0;
}
.main__shedule_pic .schedule-period table td h2 {
  margin: 4px;
}
.main__shedule_pic .schedule-period button {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: white;
  background: #00CAC0;
  width: 272px;
  height: 40px;
  border-radius: 3px;
  border: none;
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.main__shedule_pic .schedule-period button .production-icone {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  vertical-align: bottom;
}

.main__fast-print {
  padding-bottom: 30px;
}

.date-time-dialog span {
  font-family: Cabin;
}
.date-time-dialog p {
  font-family: Cabin;
}
.date-time-dialog .MuiPickersDay-daySelected {
  background: #00CAC0;
}
.date-time-dialog hr {
  border: 1px solid #C4C4C4;
}
.date-time-dialog .time-picker {
  display: table;
  position: relative;
  width: 100%;
  padding: 16px 6px;
}
.date-time-dialog .time-picker img {
  display: inline;
  vertical-align: middle;
}
.date-time-dialog .time-picker h3 {
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
  color: #333333;
  display: inline;
  margin-left: 8px;
  margin-right: 24px;
  vertical-align: middle;
}
.date-time-dialog .time-picker .MuiTextField-root {
  margin-bottom: 0 !important;
}
.date-time-dialog .time-picker .MuiFormControl-root.MuiTextField-root {
  position: absolute;
  transform: translateY(-15%);
}
.date-time-dialog .time-picker .MuiFormControl-root.MuiTextField-root label {
  display: none;
}
.date-time-dialog .time-picker .MuiFormControl-root.MuiTextField-root fieldset {
  display: none;
}
.date-time-dialog .time-picker .MuiFormControl-root.MuiTextField-root input {
  padding: 8px 2px 8px 2px;
  width: 90px;
  margin: 0;
  height: 20px;
  border: 1px solid #00CAC0;
  border-radius: 3px;
  background: white;
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;
}

.pic-details-table {
  overflow-y: scroll;
}

.radio-label-printers {
  display: flex;
}

.radio-label-printers > h3 {
  margin-right: 5px;
}

.machine-choice-item > .rs-radio-checker {
  padding-top: 5px;
  padding-bottom: 5px;
}

.machine-choice-item > .rs-radio-checker > label {
  margin-left: 0;
}

.radio-label-printers-main-printer {
  margin-left: 5px !important;
}

.main__card-post-treatment {
  width: 100%;
  height: 100%;
  padding: 9px 6px 9px 6px;
}
.main__card-post-treatment .block .select-method {
  text-align: center;
}
.main__card-post-treatment .block .select-method button {
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 24px;
  width: 135px;
  height: 60px;
  border-radius: 3px;
  background: #F8F8F8;
  border: 0;
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
}
.main__card-post-treatment .block .select-method button.selected {
  border: 1px solid #00CAC0;
}
.main__card-post-treatment .block .select-method .react-time-picker button.react-time-picker__clock-button {
  display: none;
}
.main__card-post-treatment .block .select-method .react-time-picker button.react-time-picker__clear-button.react-time-picker__button {
  display: none;
}
.main__card-post-treatment .block .select-method .react-time-picker .react-time-picker__wrapper {
  width: 100px;
  height: 30px;
  border: 0;
  border-radius: 3px;
  background: #F8F8F8;
}
.main__card-post-treatment .block .select-method .react-time-picker div.react-time-picker__inputGroup {
  width: fit-content;
  min-width: 0;
}
.main__card-post-treatment .block .select-method .react-time-picker .react-time-picker__clock.react-time-picker__clock--open {
  display: none;
}
.main__card-post-treatment .block .select-method .react-time-picker input {
  padding: 0;
  margin: 0;
  border: 0;
  background: #F8F8F8;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #333333;
}
.main__card-post-treatment .block .select-method .react-time-picker input:focus {
  box-shadow: none;
  transform: none;
}
.main__card-post-treatment .block .select-method .react-time-picker span {
  padding: 0;
  margin: 0;
  border: 0;
  background: #F8F8F8;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #333333;
}
.main__card-post-treatment .block textarea {
  background: white;
  resize: none;
}
.main__card-post-treatment .block textarea:focus {
  transform: none;
  box-shadow: none;
}

.main__card-machine-job {
  width: 100%;
  height: 100%;
  padding: 9px 6px 9px 6px;
}

.main__card-machine-job .controls {
  text-align: center;
  user-select: none;
}

.main__card-machine-job p {
  text-align: center;
}

.main__card-machine-job .controls .plus {
  display: inline-block;
  border: 2px dashed #0B4FD3;
  height: 50px;
  line-height: 50px;
  width: 40px;
  text-align: center;
  cursor: pointer;
}

.main__card-machine-job .controls .plus:hover {
  background: white;
}

.main__card-machine-job .controls .plus img {
  vertical-align: -3px;
}

.main__card-machine-job .controls .total-duration {
  display: inline-block;
  line-height: 50px;
  width: 40%;
}

.main__card-machine-job .controls .total-duration .flex-duration {
  display: flex;
}

.main__card-machine-job .controls .total-duration .previous-duration {
  display: inline-block;
  height: 50px;
  text-align: center;
  background: #00CAC0;
  border: 2px solid #00CAC0;
  color: white;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.main__card-machine-job .controls .total-duration .print-duration {
  display: inline-block;
  height: 50px;
  text-align: center;
  background: #0B4FD3;
  border: 2px solid #0B4FD3;
  color: white;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.main__card-machine-job .controls .total-duration .extra-duration {
  display: inline-block;
  height: 50px;
  text-align: center;
  background: #00CAC0;
  border: 2px solid #00CAC0;
  color: white;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.main__card-machine-job .slot-container {
  display: flex;
  align-items: center;
}
.main__card-machine-job .slot-container button:hover {
  background-color: #00CAC0;
}

.main__card-smart-farm {
  width: 100%;
  height: 100%;
  padding: 30px;
}
.main__card-smart-farm form {
  height: auto;
}
.main__card-smart-farm input[type=time]::-webkit-calendar-picker-indicator {
  background: none;
}
.main__card-smart-farm input[type=time] {
  text-align: center;
}
.main__card-smart-farm input::-webkit-outer-spin-button,
.main__card-smart-farm input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.main__card-smart-farm input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
  text-align: center;
}
.main__card-smart-farm .block img.enable {
  cursor: pointer;
}
.main__card-smart-farm .MuiTextField-root {
  margin-bottom: 15px;
}

.main__schedule_pic_table .title {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
}
.main__schedule_pic_table .flex {
  display: flex;
  justify-content: space-between;
}
.main__schedule_pic_table h2 {
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  width: auto;
  color: #333333;
}
.main__schedule_pic_table .rs-table-cell {
  display: flex;
}
.main__schedule_pic_table .rs-table-cell-content {
  text-overflow: ellipsis !important;
}
.main__schedule_pic_table .rs-table-row:hover {
  cursor: pointer;
}
.main__schedule_pic_table .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
.main__schedule_pic_table .rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.main__schedule_pic_table .rs-table-hover .rs-table-row:hover .rs-table-cell {
  background-color: #f5f5f5;
}
.main__schedule_pic_table .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
.main__schedule_pic_table .rs-table-hover .rs-table-row-header:hover .rs-table-cell {
  background-color: unset;
}
.main__schedule_pic_table .rs-table-cell-header-icon-sort {
  fill: #00CAC0;
}
.main__schedule_pic_table .search-bar {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
  width: 40%;
}
.main__schedule_pic_table .search-bar-input {
  width: 100%;
}
.main__schedule_pic_table .search-bar-addon-icon {
  height: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 20px !important;
}

.main__pic-manager .multi-blocks-inline {
  display: flex;
  justify-content: space-between;
}
.main__pic-manager .block-inline-small {
  width: 30%;
}
.main__pic-manager .block-inline-large {
  width: 68%;
}
.main__pic-manager .block {
  padding: 24px;
  background: #F8F8F8;
  border-radius: 16px;
}
.main__pic-manager .block-header {
  display: flex;
  justify-content: space-between;
  min-height: 37px;
}
.main__pic-manager h2 {
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #333333;
}
.main__pic-manager h3 {
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 2;
  color: #333333;
  font-size: 14px;
}
.main__pic-manager .rounded-block-button {
  background-color: #00CAC0;
  border-radius: 100%;
  height: 37px;
  width: 37px;
  padding: 0;
}
.main__pic-manager .rounded-block-button svg {
  margin: 0;
}
.main__pic-manager .rounded-block-button svg path {
  fill: white;
}
.main__pic-manager .rounded-block-button:hover {
  background-color: #00BDB3;
  transition: all 0.2s ease-in-out;
}

.main__pic_table {
  margin-top: 20px;
  margin-bottom: 20px;
  /*.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover[data-depth="1"],
  .rs-table-hover .rs-table-row:hover[data-depth="1"] .rs-table-cell-group,
  .rs-table-hover .rs-table-row:hover[data-depth="1"] .rs-table-cell {
    background-color: white;
  }*/
}
.main__pic_table .rs-table-row {
  border-bottom: 3px solid white !important;
}
.main__pic_table .rs-table-row[data-depth="0"] > .rs-table-cell-group > .rs-table-cell {
  font-weight: bold;
}
.main__pic_table .rs-table .rs-table-row:not(.rs-table-row-header),
.main__pic_table .rs-table .rs-table-row:not(.rs-table-row-header) .rs-table-cell-group,
.main__pic_table .rs-table .rs-table-row:not(.rs-table-row-header) .rs-table-cell {
  background-color: #F0F0F0;
}
.main__pic_table .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
.main__pic_table .rs-table-hover .rs-table-row-header:hover .rs-table-cell {
  background-color: unset;
}
.main__pic_table .rs-table-cell-header-icon-sort {
  fill: #00CAC0;
}
.main__pic_table .rs-table-cell {
  display: flex;
}
.main__pic_table .rs-table-cell > div {
  text-overflow: ellipsis;
}
.main__pic_table .rs-table-cell[role=columnheader] > .rs-table-cell-content {
  font-size: 14px;
  font-weight: bold;
}
.main__pic_table .button-table-link {
  margin-top: -11px;
  margin-right: 5px;
  background-color: white;
  height: 38px;
  display: flex;
  align-items: end;
}
.main__pic_table .button-table-link > svg {
  margin: 0;
}
.main__pic_table .action-buttons {
  display: flex !important;
  width: 15px;
  justify-content: center;
}
.main__pic_table .action-buttons:hover {
  cursor: pointer !important;
}
.main__pic_table .table-user-avatar {
  margin-top: -5px;
}
.main__pic_table .table-status {
  font-size: 12px;
  color: white;
  padding: 0 7px;
  border-radius: 10px;
  cursor: default;
  user-select: none;
  width: fit-content;
}
.main__pic_table .table-status.table-status-draft {
  background-color: #6C757D;
}
.main__pic_table .table-status.table-status-active {
  background-color: #2CEAA8;
}
.main__pic_table .project-color {
  width: 10px;
  height: 10px;
  margin-right: 7px;
  border-radius: 10px;
  display: inline-block;
}

.main__pic_actions_menu {
  padding-left: 0;
  margin-bottom: 0;
}
.main__pic_actions_menu .actions-menu-icon {
  padding-right: 10px;
}
.main__pic_actions_menu .rs-dropdown-item {
  font-size: 14px !important;
}
.main__pic_actions_menu .rs-dropdown-item:hover {
  background-color: #f5f5f5;
}

.main__pic-manage-projects-modal .modal__dialog {
  width: 650px !important;
}
.main__pic-manage-projects-modal .projects-list {
  margin-top: 15px;
  padding-bottom: 10px;
  min-height: 110px;
  max-height: 400px;
  overflow-y: auto;
}
.main__pic-manage-projects-modal .projects-list-item {
  width: 45%;
  margin-right: 5%;
  min-height: 43px;
}
.main__pic-manage-projects-modal .projects-list-item-line {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.main__pic-manage-projects-modal .projects-list-item-label {
  max-width: 130px;
}
.main__pic-manage-projects-modal .projects-list-item-quantity {
  display: flex;
}
.main__pic-manage-projects-modal .projects-list-item-quantity-input {
  margin: -7px 0 0 5px;
  width: 50px;
  height: 28px;
}
.main__pic-manage-projects-modal .rs-checkbox-checker {
  min-height: 32px !important;
  display: flex;
  align-items: center;
}
.main__pic-manage-projects-modal .rs-checkbox-checker > label {
  padding-top: 2px;
}

.main__pic-create-project-modal .create-project-form {
  margin-top: 15px;
}
.main__pic-create-project-modal .form-input-group {
  margin-bottom: 8px;
}
.main__pic-create-project-modal textarea {
  max-width: 100%;
  height: 170px;
  resize: none;
}
.main__pic-create-project-modal label, .main__pic-create-project-modal select, .main__pic-create-project-modal input {
  margin-left: 0;
}
